import React from "react";
import Layout from "../components/Layout/Layout";

const ConstructionPage = () => {
  return (
    <Layout title={"Bear Fruit Campaigns"} noHeader={true} >
      <div className="profile-container">
        {/* <h1 className="profile-heading">Profile</h1> */}
        <div className="profile-info">
          <h2 className="profile-name">Under Construction</h2>
        </div>
      </div>
    </Layout>
  );
};

export default ConstructionPage;

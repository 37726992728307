import React from "react";
import Layout from "../components/Layout/Layout";

const Terms = () => {
  return (
    <Layout title={"Terms and Conditions"}>
      <div className="topSection">
        <div className="container-fluid" style={{ lineHeight: "1.3" }}>
          <p className="headingA mb-0">Terms and Conditions</p>
          <p className="paragraph" style={{ fontSize: 12 }}>
            Last updated on June 12, 2023
          </p>
          <p className="paragraph">
            These terms and conditions ("Terms") constitute a legally binding
            agreement between you ("User," "you," or "your") and TOBTEK SRL
            ("Company," "we," "us," or "our"). By using the Bear Fruit Campaigns
            platform, available at bearfruitcampaigns.com and/or the Bear Fruit
            Campaigns mobile application ("Platform"), you agree to comply with
            and be bound by these Terms. If you do not agree with these Terms,
            please do not use the Platform.
          </p>
          <p className="paragraph">
            Description of the Platform: The Bear Fruit Campaigns Platform is
            designed to provide users with access to various features and
            functionalities.
          </p>
          <li type="1" className="paraHeading">
            Platform Concept
          </li>
          <p className="paragraph">
            1.1. The Platform operates as an online service that facilitates
            users' participation in raffle draws, providing them with the
            opportunity to potentially win cryptocurrency-based monetary
            rewards. However, the scope of the Platform extends beyond the mere
            organization of raffles, encompassing a philanthropic element.
            Notably, a significant portion of the user's contribution is
            specifically allocated by the Company towards supporting charitable
            causes that are carefully chosen.
          </p>

          <p className="paragraph">
            1.2. Users are afforded the option to nominate charitable causes
            they deem deserving of donations. Subsequently, the Company
            undertakes rigorous research measures to verify the legitimacy of
            these nominated causes. Upon approval, the nominated cause is
            included in the catalogue of trusted charitable organizations. Each
            campaign conducted on the Platform is tailored to raise funds for a
            specific cause, incorporating varying contribution amounts and
            raffle reward structures.
          </p>
          <p className="paragraph">
            1.3. Users possess the freedom to determine which campaigns they
            wish to support by making contributions. However, to ensure
            equitable participation, a maximum contribution limit of $3,000 per
            campaign is imposed. Upon the culmination of a campaign, determined
            by the exhaustion of available entries, the designated charitable
            organization receives the target donation amount associated with
            that campaign. Simultaneously, the raffle reward amount is awarded
            to one of the contributors who actively participated in that
            specific campaign.
          </p>
          <p className="paragraph">
            1.4. To uphold the principles of fairness and deter fraudulent
            practices, the selection of the raffle winner is conducted through
            the utilization of randompicker.com, an anti-cheating, unbiased
            drawing platform, trusted by thousands of companies worldwide. All
            contributors are afforded the means to verify their inclusion within
            the draw using their Name and Raffle ID, thereby fostering
            transparency and instilling confidence in the selection process. The
            winner of the raffle is presented with the cryptocurrency reward,
            and their name is publicly displayed both on the Platform and
            randompicker.com.
          </p>
          <p className="paragraph">
            1.5. Acknowledging the significance of transparency and
            accountability concerning charitable donations, the Company may
            furnish evidence of transfer for each campaign's charitable
            contribution. This serves as a testament to the funds being
            successfully transferred to the designated charitable organization,
            reinforcing the Platform's unwavering commitment to transparency and
            ensuring the intended utilization of donated funds.
          </p>
          <p className="paragraph">
            1.6. All contribution fees are final, and the Company does not offer
            any money-back guarantees. You recognize and agree that you shall
            not be entitled to a refund for any contribution under any
            circumstances.
          </p>

          <li type="1" className="paraHeading">
            Target Audience
          </li>
          <p className="paragraph">
            The Platform is available to anyone above the age of 18 worldwide.
            However, it is the user's responsibility to check and comply with
            the laws and regulations of their country of residence regarding
            participation in raffles.
          </p>
          <li type="1" className="paraHeading">
            User Information
          </li>
          <p className="paragraph">
            3.1. In order to use the Platform, users are required to provide
            accurate and complete information, including their full name, email
            address, phone number, date of birth, nationality, and country of
            residence.
          </p>
          <p className="paragraph">
            3.2. “Services” means any and all services and facilities (including
            Campaigns and the facility to make direct Donations) which we may
            make available for your use through the Website/App;
          </p>
          <li type="1" className="paraHeading">
            Winner Verification
          </li>
          <p className="paragraph">
            In order to claim a campaign reward and ensure transparency and
            trust within our user community, the selected winner of a raffle
            must comply with the following verification requirements:
          </p>
          <p className="paragraph">
            4.1. Account Verification: The winner must verify their account
            details using a government issued document, including but not
            limited to their name, email address, and contact information, as
            provided during the registration process. Failure to provide
            accurate and complete information may result in disqualification and
            forfeiture of the reward.
          </p>
          <p className="paragraph">
            4.2. Social Media Account Sharing: The winner must publicly share at
            least one of their active social media accounts on the platform.
            This is to demonstrate to other users that real people are winning
            the raffles and to build trust within the community. The shared
            account(s) should be genuine and not created solely for the purpose
            of the raffle. The platform reserves the right to determine the
            authenticity and legitimacy of the shared social media account(s).
          </p>
          <p className="paragraph">
            4.3. Video Confirmation (if no social media account available): If
            the winner does not have any active social media accounts, they must
            record a video of themselves stating their full name and confirming
            that they have won the raffle draw. This video may be used by the
            platform for promotional purposes and to validate the authenticity
            of the winner's claim.
          </p>
          <p className="paragraph">
            Failure to comply with any of the above verification requirements
            within a specified timeframe, as communicated by the platform, may
            result in the forfeiture of the reward. In such cases, a redraw will
            be conducted to determine a new winner.
          </p>
          <p className="paragraph">
            By participating in the raffle, you acknowledge and agree to comply
            with the winner verification requirements outlined above. The
            platform reserves the right to request additional documentation or
            information if deemed necessary to validate a winner's claim. All
            verification procedures will be conducted in accordance with
            applicable privacy laws and regulations.
          </p>

          <li type="1" className="paraHeading">
            Additional Details
          </li>
          <p className="paragraph">
            In order to develop the most comprehensive and accurate terms and
            conditions for the Platform, a thorough review of the frequently
            asked questions (FAQ) available on the Platform has been conducted.
            These resources contain valuable information regarding the
            operational aspects, rules, and user guidelines of the Platform.
            Relevant information from these sources has been carefully
            considered and incorporated into the terms and conditions to ensure
            all necessary details are included. By referencing the FAQ, users
            can access additional information and gain a comprehensive
            understanding of the Platform's operations and guidelines.
          </p>
          <li type="1" className="paraHeading">
            Incorporation and Legal Compliance
          </li>
          <p className="paragraph">
            6.1. The Company, named TOBTEK SRL, is a duly incorporated entity in
            accordance with the laws of Costa Rica, with its registered office
            located in San Jose, Costa Rica. The Company operates the Platform
            legally under the applicable legal framework of Costa Rica.
          </p>
          <p className="paragraph">
            6.2. By accessing and using the Platform, you acknowledge and agree
            to be bound by the laws and regulations of Costa Rica that govern
            the activities and operations of the Platform. These laws include,
            but are not limited to, the laws pertaining to online platforms,
            games of chance, raffles, cryptocurrency, and charitable
            contributions, among others.
          </p>
          <p className="paragraph">
            6.3. The legal incorporation of the Company in Costa Rica ensures
            compliance with local laws and regulations, as well as adherence to
            any relevant international obligations that Costa Rica may have
            undertaken.
          </p>
          <p className="paragraph">
            6.4. It is important to note that the laws and regulations of Costa
            Rica may vary from those of other jurisdictions. Therefore, by using
            the Platform, you understand and accept that the legal requirements,
            rights, and obligations applicable to the Platform are based on the
            laws of Costa Rica.
          </p>
          <p className="paragraph">
            6.5. The Company endeavors to operate the Platform in full
            compliance with the legal requirements of Costa Rica, including
            obtaining any necessary licenses or permits required by the
            competent authorities to provide the services offered on the
            Platform.
          </p>
          <p className="paragraph">
            6.6. In the event of any disputes or legal issues arising from the
            use of the Platform, you agree to submit to the exclusive
            jurisdiction of the courts of Costa Rica and waive any objections or
            claims relating to jurisdiction or venue.
          </p>
          <li type="1" className="paraHeading">
            Modification of Terms
          </li>
          <p className="paragraph">
            The Company reserves the right to modify these Terms at any time
            without prior notice. Any updates or changes to the Terms will be
            effective upon posting on the Platform. It is your responsibility to
            review the Terms periodically to stay informed about any
            modifications.
          </p>
          <li type="1" className="paraHeading">
            Limitation of Liability
          </li>
          <p className="paragraph">
            8.1. The Company shall not be liable for any indirect, incidental,
            special, consequential, or exemplary damages arising out of or in
            connection with your use of the Platform. This includes, but is not
            limited to, damages for loss of profits, data, or other intangible
            losses, even if the Company has been advised of the possibility of
            such damages.
          </p>
          <p className="paragraph">
            8.2. You agree that the Company's total liability, regardless of the
            cause of action, shall not exceed the total amount contributed by
            you to the Platform during your use of the Platform.
          </p>
          <p className="paragraph">
            8.3. The Company does not guarantee the availability, accuracy, or
            reliability of the Platform or its content. While the Company takes
            reasonable measures to maintain the security and functionality of
            the Platform, you acknowledge that no system is completely secure
            and that the Company cannot guarantee the continuous, uninterrupted,
            or error-free operation of the Platform.
          </p>
          <li type="1" className="paraHeading">
            Intellectual Property
          </li>
          <p className="paragraph">
            9.1. The Platform and all its content, including but not limited to
            text, graphics, logos, images, software, designs, trademarks,
            service marks, trade names, trade dress, and other materials
            (collectively referred to as "Intellectual Property"), are the
            exclusive property of TOBTEK SRL and are protected by applicable
            copyright, trademark, and other intellectual property laws.
          </p>
          <p className="paragraph">
            9.2. You acknowledge and agree that the Intellectual Property of the
            Platform is owned by TOBTEK SRL, and by using the Platform, you do
            not acquire any ownership or intellectual property rights in the
            Platform or its content.
          </p>
          <p className="paragraph">
            9.3. You are granted a limited, non-exclusive, non-transferable,
            revocable license to access and use the Platform solely for your
            personal, non-commercial use in accordance with these Terms.
          </p>
          <p className="paragraph">
            9.4. You agree not to modify, reproduce, distribute, create
            derivative works from, publicly display, perform, or transmit any
            part of the Platform or its content without the prior written
            consent of TOBTEK SRL.
          </p>
          <p className="paragraph">
            9.5. You shall not remove, alter, or obscure any copyright,
            trademark, or other proprietary rights notices contained on or
            within the Platform or its content.
          </p>
          <p className="paragraph">
            9.6. You may use the Platform and its content for lawful purposes
            only and in accordance with these Terms. Any unauthorized use of the
            Intellectual Property may violate intellectual property laws and
            other applicable laws and regulations.
          </p>
          <p className="paragraph">
            9.7. If you believe that any content on the Platform infringes upon
            your intellectual property rights, please promptly notify TOBTEK SRL
            in writing, providing the following information:
          </p>
          <ul>
            <li type="a" className="paragraph mt-3">
              A description of the copyrighted work or other intellectual
              property that you claim has been infringed;
            </li>
            <li type="a" className="paragraph mt-3">
              Sufficient information to identify the allegedly infringing
              content, including its location on the Platform;
            </li>
            <li type="a" className="paragraph mt-3">
              Your contact information, including your name, address, telephone
              number, and email address;
            </li>
            <li type="a" className="paragraph mt-3">
              A statement that you have a good-faith belief that the use of the
              content is not authorized by the intellectual property owner, its
              agent, or the law;
            </li>
            <li type="a" className="paragraph mt-3">
              A statement, made under penalty of perjury, that the information
              provided in your notice is accurate and that you are the
              intellectual property owner or authorized to act on behalf of the
              owner.
            </li>
          </ul>
          <p className="paragraph">
            9.8. TOBTEK SRL reserves the right to remove any infringing content
            from the Platform and may, in its sole discretion, terminate the
            accounts of users who repeatedly infringe upon the intellectual
            property rights of others.
          </p>
          <p className="paragraph">
            9.9. Except as expressly provided in these Terms, no part of the
            Platform or its content may be copied, reproduced, republished,
            uploaded, posted, publicly displayed, encoded, translated,
            transmitted, or distributed in any way without TOBTEK SRL's prior
            written consent.
          </p>
          <p className="paragraph">
            9.10. Any use of the Platform or its content not expressly permitted
            by these Terms is a breach of these Terms and may violate copyright,
            trademark, and other applicable laws.
          </p>
          <p className="paragraph">
            By using the Platform, you acknowledge and agree to respect the
            Intellectual Property rights of TOBTEK SRL and to abide by the terms
            outlined in this section.
          </p>
          <li type="1" className="paraHeading">
            Indemnification
          </li>
          <p className="paragraph">
            10.1. You agree to indemnify, defend, and hold harmless TOBTEK SRL,
            its affiliates, officers, directors, employees, agents, and partners
            (collectively referred to as "Indemnified Parties") from and against
            any and all claims, liabilities, damages, losses, costs, and
            expenses (including reasonable attorney's fees) incurred by the
            Indemnified Parties arising out of or in connection with:
          </p>
          <ul>
            <li type="a" className="paragraph mt-3">
              Your use of the Platform, including but not limited to any
              actions, omissions, or decisions made by you while using the
              Platform;
            </li>
            <li type="a" className="paragraph mt-3">
              Your violation of these Terms or any other applicable laws, rules,
              or regulations;
            </li>
            <li type="a" className="paragraph mt-3">
              Any content or materials uploaded, posted, transmitted, or
              otherwise made available by you on or through the Platform;
            </li>
            <li type="a" className="paragraph mt-3">
              Any breach of any representation, warranty, or covenant made by
              you in these Terms;
            </li>
            <li type="a" className="paragraph mt-3">
              Any infringement or misappropriation of any intellectual property
              rights or other rights of any third party by you; or
            </li>
            <li type="a" className="paragraph mt-3">
              Any dispute or interaction between you and any other user or third
              party arising from your use of the Platform.
            </li>
          </ul>
          <p className="paragraph">
            10.2. TOBTEK SRL reserves the right, at its own expense, to assume
            the exclusive defense and control of any matter otherwise subject to
            indemnification by you, in which event you will cooperate with
            TOBTEK SRL in asserting any available defenses.
          </p>
          <p className="paragraph">
            10.3. This indemnity shall survive the termination or expiration of
            these Terms and your use of the Platform.
          </p>
          <p className="paragraph">
            By using the Platform, you acknowledge and agree to indemnify and
            hold harmless TOBTEK SRL and its Indemnified Parties from any
            claims, liabilities, damages, losses, costs, and expenses incurred
            as outlined in this section.
          </p>
          <li type="1" className="paraHeading">
            Governing Law and Jurisdiction
          </li>
          <p className="paragraph">
            11.1. These Terms and any dispute or claim arising out of or in
            connection with these Terms, including their formation,
            interpretation, validity, performance, breach, or termination, shall
            be governed by and construed in accordance with the laws of Costa
            Rica, without giving effect to its conflict of laws principles.
          </p>
          <p className="paragraph">
            11.2. Any legal action, suit, or proceeding arising out of or
            relating to these Terms or the Platform shall be instituted
            exclusively in the courts located in San Jose, Costa Rica. You and
            TOBTEK SRL hereby irrevocably submit to the exclusive jurisdiction
            of such courts for the purpose of any such legal action, suit, or
            proceeding.
          </p>
          <p className="paragraph">
            By using the Platform, you acknowledge and agree that any disputes
            or claims arising from these Terms shall be subject to the laws and
            exclusive jurisdiction of the courts in San Jose, Costa Rica.
          </p>
          <li type="1" className="paraHeading">
            Severability
          </li>
          <p className="paragraph">
            12.1. If any provision of these Terms is held to be invalid,
            illegal, or unenforceable by a court of competent jurisdiction, the
            remaining provisions of these Terms shall remain in full force and
            effect to the maximum extent permitted by law.
          </p>
          <p className="paragraph">
            12.2. The invalidity, illegality, or unenforceability of any
            provision of these Terms shall not in any way affect or impair the
            validity, legality, or enforceability of any other provision of
            these Terms.
          </p>
          <p className="paragraph">
            12.3. In the event that any provision of these Terms is determined
            to be invalid, illegal, or unenforceable, the parties shall endeavor
            to replace such provision with a valid, legal, and enforceable
            provision that achieves, to the greatest extent possible, the
            intended economic, legal, and commercial result of the invalid,
            illegal, or unenforceable provision.
          </p>
          <p className="paragraph">
            12.4. The failure of TOBTEK SRL to enforce any provision of these
            Terms shall not be deemed a waiver of such provision or any other
            provision of these Terms.
          </p>
          <li type="1" className="paraHeading">
            Disclaimer
          </li>
          <p className="paragraph">
            The information provided on this platform is for general
            informational purposes only. While we strive to keep the information
            up to date and accurate, we make no representations or warranties of
            any kind, express or implied, about the completeness, accuracy,
            reliability, suitability, or availability with respect to the
            platform or the information, products, services, or related graphics
            contained on the platform for any purpose. Any reliance you place on
            such information is therefore strictly at your own risk.
          </p>
          <p className="paragraph">
            In no event will we be liable for any loss or damage, including
            without limitation, indirect or consequential loss or damage, or any
            loss or damage whatsoever arising from loss of data or profits
            arising out of, or in connection with, the use of this platform.
          </p>
          <p className="paragraph">
            Through this platform, you may be able to link to other websites
            that are not under our control. We have no control over the nature,
            content, and availability of those sites. The inclusion of any links
            does not necessarily imply a recommendation or endorse the views
            expressed within them.
          </p>
          <p className="paragraph">
            Every effort is made to keep the platform up and running smoothly.
            However, we take no responsibility for, and will not be liable for,
            the platform being temporarily unavailable due to technical issues
            beyond our control.
          </p>
          <li type="1" className="paraHeading">
            Copyright Notice
          </li>
          <p className="paragraph">
            All contents of the Bear Fruit Campaigns, including but not limited
            to text, graphics, logos, images, software, and other materials, are
            the intellectual property of TOBTEK SRL and are protected by
            applicable copyright laws. All rights reserved.
          </p>
          <p className="paragraph">
            Unauthorized copying, reproduction, distribution, modification, or
            any other use of the content without the prior written consent of
            TOBTEK SRL is strictly prohibited. You may not use, copy, display,
            distribute, transmit, or reproduce any of the copyrighted materials
            found on the Bear Fruit Campaigns or associated with our software
            and services without obtaining the necessary permissions.
          </p>
          <p className="paragraph">
            TOBTEK SRL respects the intellectual property rights of others, and
            we expect our users to do the same. If you believe that any content
            on the Bear Fruit Campaigns infringes your copyright, please contact
            us: legal@tobtek.com
          </p>
          <p className="paragraph">
            We will promptly investigate and take appropriate action in
            accordance with applicable copyright laws.
          </p>
          <p className="paragraph">
            By accessing and using the Bear Fruit Campaigns Platform, you
            acknowledge and agree that all intellectual property rights in the
            content and materials belong to TOBTEK SRL. Any unauthorized use of
            the content may violate copyright laws and other applicable laws and
            regulations.
          </p>
          <li type="1" className="paraHeading">
            Money Laundering and Anti-Fraud Measures
          </li>
          <p className="paragraph">
            15.1. TOBTEK SRL is committed to maintaining the highest standards
            of integrity and preventing money laundering and fraudulent
            activities on the Platform. You acknowledge and agree to comply with
            all applicable laws and regulations related to anti-money laundering
            (AML) and counter-terrorism financing (CTF) measures.
          </p>
          <p className="paragraph">
            15.2. TOBTEK SRL reserves the right to conduct due diligence and
            verification procedures on users to prevent money laundering, fraud,
            or any other illegal activities. This may include but is not limited
            to verifying the identity of users, monitoring transactions, and
            reporting suspicious activities to the relevant authorities.
          </p>
          <p className="paragraph">
            15.3. You agree to provide accurate and up-to-date information
            during the registration process and throughout your use of the
            Platform. TOBTEK SRL may request additional information or
            documentation to verify your identity or the legitimacy of your
            transactions.{" "}
          </p>
          <p className="paragraph">
            15.4. If TOBTEK SRL has reasonable grounds to suspect that you are
            engaged in money laundering, fraud, or any other illegal activities,
            TOBTEK SRL reserves the right to suspend or terminate your access to
            the Platform, freeze your account, and report your activities to the
            appropriate authorities.
          </p>
          <p className="paragraph">
            15.5. TOBTEK SRL shall not be liable for any loss or damage incurred
            by you or any third party as a result of the suspension,
            termination, or freezing of your account due to suspected money
            laundering, fraud, or other illegal activities.
          </p>
          <p className="paragraph">
            15.6. You agree to cooperate fully with TOBTEK SRL in any
            investigation related to money laundering, fraud, or other illegal
            activities. This may include providing any requested information,
            documentation, or assistance to facilitate the investigation.
          </p>
          <p className="paragraph">
            15.7. TOBTEK SRL reserves the right to refuse or reject any
            transaction or activity that it deems suspicious or in violation of
            applicable AML and CTF laws and regulations.
          </p>
          <p className="paragraph">
            15.8. By using the Platform, you acknowledge and agree that TOBTEK
            SRL may share your information with regulatory authorities, law
            enforcement agencies, or other third parties as required by law or
            necessary to comply with its legal obligations.
          </p>
          <p className="paragraph">
            15.9. TOBTEK SRL may implement additional security measures or
            procedures from time to time to prevent money laundering, fraud, or
            other illegal activities. You agree to comply with any such measures
            or procedures implemented by TOBTEK SRL.
          </p>
          <p className="paragraph">
            15.10. You acknowledge that TOBTEK SRL has the right to retain
            transaction records and user information for the period required by
            applicable laws and regulations.
          </p>
          <p className="paragraph">
            15.11. If you have any knowledge or suspicion of money laundering,
            fraud, or other illegal activities on the Platform, you must
            immediately report it to TOBTEK SRL through the designated channels
            or by contacting customer support.
          </p>
          <p className="paragraph">
            15.12. TOBTEK SRL reserves the right to update, modify, or amend the
            money laundering and anti-fraud measures outlined in this clause as
            required by changes in laws, regulations, or industry standards. Any
            updates or modifications to these measures will be effective upon
            posting on the Platform.
          </p>
          <p className="paragraph">
            15.13. The provisions of this money laundering clause shall survive
            the termination or expiration of these Terms and your use of the
            Platform.
          </p>
          <li type="1" className="paraHeading">
            Entire Agreement
          </li>
          <p className="paragraph">
            16.1. These Terms, including any additional terms or policies
            expressly incorporated by reference, constitute the entire agreement
            between you and TOBTEK SRL regarding the use of the Platform and
            supersede any prior agreements, understandings, or representations,
            whether oral or written, relating to the subject matter herein.
          </p>
          <p className="paragraph">
            16.2. In the event of any conflict between these Terms and any other
            agreement or understanding between you and TOBTEK SRL, these Terms
            shall prevail.
          </p>
          <p className="paragraph">
            16.3. You acknowledge that you have read, understood, and agreed to
            these detailed Terms and Conditions by using the Platform. If you do
            not agree to these Terms, you should not access or use the Platform.
          </p>
          <li type="1" className="paraHeading">
            Contact Us
          </li>
          <p className="paragraph">
            If you have any questions, concerns, or requests regarding these
            Terms and Conditions, please contact us: legal@tobtek.com
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;

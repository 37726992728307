import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import { useAuth } from "../context/auth";
import mongoose from "mongoose";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

const Participation = ({ loading }) => {
  const [orders, setOrders] = useState([]);
  const [ord, setOrd] = useState([]);
  const [auth, setAuth] = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const getOrders = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get("/api/v1/auth/orders");

      const productsMap = new Map();

      data.orders.forEach((order) => {
        order.products.forEach((item) => {
          const { product, quantity } = item;
          if (productsMap.has(product)) {
            productsMap.set(product, productsMap.get(product) + quantity);
          } else {
            productsMap.set(product, quantity);
          }
        });
      });

      const productElements = [];
      productsMap.forEach((quantity, product) => {
        const productDetails = data.products.find((p) => p._id === product);
        const st = productDetails.status === "Completed" ? "completed" : "";
        productElements.push({
          quantity,
          productDetails,
          st,
        });
      });

      // productElements.sort((a, b) => {
      //   if (a.st === "completed" && b.st !== "completed") {
      //     return 1; // a should be placed after b
      //   } else if (a.st !== "completed" && b.st === "completed") {
      //     return -1; // a should be placed before b
      //   } else {
      //     return 0; // no sorting needed
      //   }
      // });
      productElements.sort((a, b) => {
        if (a.st === "completed" && b.st !== "completed") {
          return 1; // a should be placed after b
        } else if (a.st !== "completed" && b.st === "completed") {
          return -1; // a should be placed before b
        } else if (a.st === "completed" && b.st === "completed") {
          // Sort by completedDate in ascending order
          return (
            new Date(b.productDetails.completedDate) -
            new Date(a.productDetails.completedDate)
          );
        } else {
          return 0; // no sorting needed
        }
      });

      const renderedElements = productElements.map(
        ({ quantity, productDetails, st }) => (
          <div
            key={productDetails._id}
            className={"col-md-4 " + "col-sm-6 " + "imageDiv " + st}
          >
            <br />

            <div className="pTagParticipation">
              <p style={{ marginBottom: 5, fontSize: 14 }} className="comp">
                Amount Contributed <span style={{ color: "#666666" }}>($)</span>
              </p>
              <p style={{ marginBottom: 0, fontSize: 14 }} className="comp">
                Number of Raffle Entries
              </p>
            </div>
            <div className="boxRight">
              <p
                style={{
                  color: "black",
                  marginBottom: 5,
                  fontSize: 14,
                  textAlign: "center",
                }}
                className="comp"
              >
                {productDetails.contributeAmount * quantity}
              </p>
              <p
                style={{
                  color: "black",
                  marginBottom: 0,
                  fontSize: 14,
                  textAlign: "center",
                }}
                className="comp"
              >
                {quantity}
              </p>
            </div>

            <div
              className="card"
              style={{ backgroundColor: "white", padding: 8, minHeight: 150 }}
            >
              <div className="tagInitiative">
                <p
                  style={{ color: "#DD8A37", marginBottom: 0, fontSize: 14 }}
                  className="comp"
                >
                  <b>{productDetails.initiative}</b>
                </p>
                <p style={{ color: "#666666", float: "left", fontSize: 12 }}>
                  <span className="comp">
                    {productDetails.status}
                    {productDetails.completedDate
                      ? ` on ${formatDate(productDetails.completedDate)} - `
                      : " - "}
                  </span>
                  <span style={{ color: "#2F7F0E", fontWeight: 600 }}>
                    {productDetails.status === "In Progress" ? (
                      `
                  ${formatNumber(productDetails.rewardAmount)} Raffle Reward`
                    ) : (
                      <Link
                        to="/campaign-results"
                        style={{ textDecoration: "none", color: "#DD8A37" }}
                      >
                        Campaign Results
                      </Link>
                    )}
                  </span>
                </p>
                <p
                  style={{ color: "#666666", float: "right", fontSize: 12 }}
                  className="comp"
                >
                  BF-{productDetails.code}
                </p>
              </div>
            </div>
          </div>
        )
      );

      setOrders(data);
      setOrd(renderedElements);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formatNumber = (number) => {
    return number.toLocaleString("en-US");
  };

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);

  return (
    <Layout title={"Participation"}>
      <div className="topSection">
        <div className="container-fluid p-0" style={{ lineHeight: "1.3" }}>
          <p className="headingA">PARTICIPATION</p>
          {/* <p>{JSON.stringify(orders, null, 4)}</p>
          <br /> */}

          <p className="paragraph">
            On behalf of all those in need, we thank you for your generous
            contribution, may it bear fruit for you.
          </p>
          <p className="paragraph">
            Once a Campaign is complete, the raffle draw will be conducted, and
            the results will be available below for public viewing.
          </p>
          <p className="paragraph">
            Refer a friend for a chance to win the Fund a Cause Campaign raffle
            reward without financially contributing. For each friend you refer,
            you earn one entry in the draw.
          </p>
        </div>
      </div>

      {/* card section */}
      <div className="topSection">
        <div className="container-fluid p-0">
          <div className="row">
            {auth?.token ? (
              <>
                {isLoading && (
                  <>
                    <p></p>
                    <div className="col-md-4 col-sm-6">
                      <Skeleton count={3} height="150px" width="100%" />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <Skeleton count={3} height="150px" width="100%" />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <Skeleton count={3} height="150px" width="100%" />
                    </div>
                  </>
                )}
                {ord.length > 0 ? (
                  ord
                ) : (
                  <p
                    className="paragraph"
                    style={{ color: "#DD8A37", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/beta");
                    }}
                  >
                    Not Participated Yet
                  </p>
                )}
              </>
            ) : (
              <p
                className="paragraph"
                style={{ color: "#DD8A37", cursor: "pointer" }}
                onClick={() => {
                  navigate("/login", {
                    state: "/participation",
                  });
                }}
              >
                Log in to view your Participation
              </p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Participation;

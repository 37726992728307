import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import UnscrollableSection from "./UnScrollableSection";

const Layout = ({ children, title, description, keywords, noHeader }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <title>{title}</title>
      </Helmet>
      {noHeader ? "" : <Header />}
      {/* {noHeader ? "" : <UnscrollableSection />} */}
      <main
        style={{
          minHeight: "92vh",
          backgroundColor: "#F8F8FE",
          paddingTop: 105,
          paddingBottom: 28,
        }}
        // style={{
        //   minHeight: "92vh",
        //   backgroundColor: "#F8F8FE",
        //   paddingTop: 105,
        //   paddingBottom: 28,
        //   overflow: "hidden", // Wrap "hidden" in quotes
        //   height: "calc(100vh - 105px)" // Wrap calc function in quotes
        // }}
        // style={{
        //   minHeight: "calc(100vh - 105px)",
        //   backgroundColor: "#F8F8FE",
        //   paddingTop: 105,
        //   paddingBottom: 28,
        //   overflow: "hidden",
        // }}
      >
        <Toaster />
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  title: "Bear Fruit Campaigns",
  description: "Help those in need & your life may change!",
  keywords: "bearfruit, bear fruit campaigns, help others,become rich easily",
};

export default Layout;

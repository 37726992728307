import React from "react";
import Layout from "../components/Layout/Layout";

const Pagenotfound = () => {
  return (
    <Layout title={"Page not found"}>
      <div className="pnf">
      <img src="../../images/404.png" alt="Centered Image" style={{ maxWidth: '50%', maxHeight: '50%' }} />
    </div>
    </Layout>
  );
};

export default Pagenotfound;

import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useAuth } from "../context/auth";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AdminPage = () => {
  const [auth, setAuth] = useAuth();
  const [users, setUsers] = useState([]);
  const [profile, setProfile] = useState([]);
  // const textToCopy = `https://bearfruitcampaigns.com/referral/${auth?.user?.uId}`;
  const navigate = useNavigate();

  //get all products
  const getAllEmails = async () => {
    try {
      const { data } = await axios.get("/api/v1/auth/get-all-emails");
      setUsers(data.users);
    } catch (error) {
      console.log(error);
    }
  };

  const massStart = async () => {
    try {
      const { data } = await axios.get("/api/v1/auth/mass-start");
      //setUsers(data.users);
      if (data.success === true) {
        console.log("Done");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //GET PROFILE DETAILS
  const getProfileDetails = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-profile");
      setProfile(data.profile);
    } catch (error) {
      console.log("error at referral page in getting profile details", error);
    }
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://bearfruitcampaigns.com/referral/${profile?.raffleId}`
      );
      console.log("Text copied to clipboard");
      toast.success("Referral Link Copied");
    } catch (error) {
      console.log("Failed to copy text: ", error);
      toast.error("Failed to copy Referral Link");
    }
  };

  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Bear Fruit Campaigns",
          text: "Win Big and Make a Difference",
          url: `https://bearfruitcampaigns.com/referral/${profile?.raffleId}`,
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.log("Failed to share content: ", error);
        toast.error("Failed to share Referral Link");
      }
    } else {
      console.log("Web Share API not supported");
      toast.error("Sharing not supported in your browser");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    if (auth?.token) {
      getAllEmails();
      getProfileDetails();
    }
  }, [auth?.token]);

  return (
    <Layout title={"Referrals"}>
      <div className="row w-100 m-auto">
        <div className="col-lg-6">
          <div className="topSection">
            <div className="container-fluid p-0" style={{ lineHeight: "1.3" }}>
              <p className="headingA">Admin Panel</p>

              {auth?.token ? (
                <>
                  <p
                    className="paragraph"
                    style={{ marginBottom: 0, fontSize: 14 }}
                  >
                    All Emails uploaded
                  </p>
                  <br />
                  <div style={{ float: "right" }}>
                    <button
                      className="btn btStandardFill"
                      style={{ marginRight: 7, marginBottom: 7, paddingTop: 0 }}
                      onClick={massStart}
                    >
                      Start
                    </button>
                    <button
                      className="btn btStandardFillGray"
                      style={{ marginLeft: 7, marginBottom: 7, paddingTop: 0 }}
                    >
                      Stop
                    </button>
                  </div>

                  <br />
                  <table className="table table-hover table-bordered border-dark referral-table">
                    <thead>
                      <tr style={{ color: "#666666" }}>
                        <td scope="col">Email</td>
                        <td scope="col">Date</td>
                        <td scope="col">Status</td>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.map((r) => (
                        <tr
                          style={{ color: r.mass101 ? "green" : "black" }}
                          key={r._id}
                        >
                          <td>{r.email}</td>
                          <td>
                            {r.mass101Time ? formatDate(r.mass101Time) : ""}
                          </td>
                          <td>{r.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <p
                  className="paragraph"
                  style={{ color: "#DD8A37", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/login", {
                      state: "/referrals",
                    });
                  }}
                >
                  Log In to view your Referral Link and referrals
                </p>
              )}
            </div>
          </div>
        </div>{" "}
        <div className="col-lg-6"></div>
      </div>
    </Layout>
  );
};

export default AdminPage;

import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../context/auth";
import Swal from "sweetalert2";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
// import { FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLoginButton } from "react-social-login-buttons";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [customFieldVisible, setCustomFieldVisible] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const idd = queryParams.get("i");
  const passs = queryParams.get("p");

  useEffect(() => {
    if (idd && passs) {
      autoSubmit(idd, passs);
    }
  }, []);

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/v1/auth/login", {
        email,
        password,
      });
      if (res && res.data.success) {
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(location.state || "/profile");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // Submit form AUTO
  const autoSubmit = async (email, password) => {
    console.log("eeeeee", email, password);
    try {
      const res = await axios.post("/api/v1/auth/login", {
        email,
        password,
      });
      console.log("11");
      if (res && res.data.success) {
        console.log("22");
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(location.state || "/profile");
      } else {
        console.log("33");
        switch (res.data.message) {
          case "Email is not Registered":
            toast.error("Account not found");
            break;
          case "Invalid Email or Password":
            toast.error("Use other signin option");
            break;
          default:
            toast.error("Something went wrong");
            break;
        }
      }
    } catch (error) {
      console.log("44");
      console.log("55", error);
      toast.error("Login Error!");
    }
  };

  // facebook login start

  const lol = () => {
    console.log("Pressed");
    window.FB.login(
      function (response) {
        if (response.status === "connected") {
          // Logged into your webpage and Facebook.
          // console.log(response)
          window.FB.api(
            "/me",
            "GET",
            { fields: "id,name,birthday,picture{url},gender,email" },
            function (response) {
              console.log(response);
              autoSubmit(response.email, response.id);
            }
          );
        } else {
          // The person is not logged into your webpage or we are unable to tell.
        }
      },
      { scope: "email" }
    );
  };

  // facebook login end

  // // google login start
  // function handleCredentialResponse(response) {
  //   const responsePayload = decodeJwtResponse(response.credential);

  //   autoSubmit(responsePayload.email, responsePayload.sub);
  // }

  // function decodeJwtResponse(cred) {
  //   var jwt = cred;

  //   var tokens = jwt.split(".");

  //   console.log(JSON.parse(atob(tokens[0])));
  //   console.log(JSON.parse(atob(tokens[1])));

  //   return JSON.parse(atob(tokens[1]));
  // }

  // useEffect(() => {
  //   // Initialize Google Sign-In
  //   window.google?.accounts?.id.initialize({
  //     client_id:
  //       "41600934199-0m26mer0a8rege0e9n7j01om4irjc4ee.apps.googleusercontent.com",
  //     callback: handleCredentialResponse,
  //   });

  //   // Render the Google Sign-In button
  //   window.google?.accounts?.id.renderButton(
  //     document.getElementById("google-sign-in-button"),
  //     {
  //       theme: "outline",
  //       size: "large",
  //       type: "standard",
  //       text: "continue_with",
  //       locale: "en",
  //       width: 280,
  //     }
  //   );
  // }, []);

  // google login end

  // apple login start
  useEffect(() => {
    // Initialize Apple Sign-In
    window.AppleID.auth.init({
      clientId: "com.bearfruitcampaigns.campaigns",
      scope: "name email",
      redirectURI: "https://bearfruitcampaigns.com/api/v1/auth/login-apple",
      // state: '[YOUR_STATE_VALUE]',
    });

    // Event listener for Apple Sign-In button click
    const handleAppleSignIn = () => {
      window.AppleID.auth.signIn();
    };

    // Event listener for Apple Sign-In onSignIn event
    const handleAppleSignInResponse = (response) => {
      const authorizationCode = response.authorization.code;
      console.log(
        ",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,",
        response
      );
      // Use the authorization code to authenticate the user on your server
    };

    // Attach event listeners

    // for purpose
    document
      .getElementById("apple")
      .addEventListener("click", handleAppleSignIn);
    // for purpose end

    // window.AppleID.auth.onSignIn(handleAppleSignInResponse);

    // Clean up event listeners on component unmount
    // return () => {
    //   // document
    //   //   .getElementById("apple")
    //   //   .removeEventListener("click", handleAppleSignIn);
    //  // window.AppleID.auth.onSignIn(handleAppleSignInResponse);
    // };
  }, []);

  // apple login end

  const doPass = async () => {
    try {
      if (email) {
        const res = await axios.post("/api/v1/auth/send-reset-password-link", {
          email: email,
          userLoggedIn: false,
        });

        console.log("Email response", res?.data?.message);
        Swal.fire({
          text: res?.data?.message,
          confirmButtonColor: "#DD8A37",
          customClass: {
            confirmButton: "btt",
          },
        });
      } else {
        Swal.fire({
          text: "Type your email first",
          confirmButtonColor: "#DD8A37",
          customClass: {
            confirmButton: "btt",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout title={"Account"}>
      <div className="row w-100 m-auto">
        <div className="col-lg-6 p-0">
          <div className="topSection">
            <div className="container-fluid p-0" style={{ lineHeight: "1.3" }}>
              <p
                className="paragraph"
                style={{ marginBottom: 0, fontSize: 12, float: "right" }}
              >
                Don't have an account?{" "}
                <Link to="/create-account" className="aTags paragraph">
                  Create Account
                </Link>
              </p>
              <br />
              <br />
              <p className="headingA">LOG IN</p>

              <div
                className="card"
                style={{
                  padding: "2rem 0",
                  minWidth: 300,
                }}
              >
                {/* custom login start */}
                <div
                  className="card mb-3"
                  id="customButton"
                  onClick={() => setCustomFieldVisible(true)}
                  style={{
                    border: "thin solid rgb(224, 224, 224)",
                    boxShadow: "none",
                    borderRadius: 5,
                    height: 40,
                    width: 280,
                    cursor: "pointer",
                    margin: "auto",
                  }}
                >
                  <img
                    src="../images/symbol.png"
                    style={{
                      position: "absolute",
                      width: 24,
                      height: "auto",
                      top: 7,
                      left: 7,
                    }}
                  />
                  <p
                    className="m-0 pt-2"
                    style={{ fontSize: ".9rem", paddingLeft: 79 }}
                  >
                    Continue with Email
                  </p>
                </div>
                {/* custom login end */}

                {/* google login start */}

                <LoginSocialGoogle
                  client_id={
                    "41600934199-0m26mer0a8rege0e9n7j01om4irjc4ee.apps.googleusercontent.com"
                  }
                  scope="openid profile email"
                  discoveryDocs="claims_supported"
                  access_type="offline"
                  onResolve={({ provider, data }) => {
                    // console.log(provider, data);
                    // console.log("dd",data.email,data.sub)
                    autoSubmit(data.email, data.sub);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <div
                    className="card mb-3"
                    style={{
                      border: "thin solid rgb(224, 224, 224)",
                      boxShadow: "none",
                      borderRadius: 5,
                      height: 40,
                      width: 280,
                      cursor: "pointer",
                      margin: "auto",
                    }}
                  >
                    <img
                      src="../images/google.png"
                      style={{
                        position: "absolute",
                        width: 24,
                        height: "auto",
                        top: 7,
                        left: 7,
                      }}
                    />
                    <p
                      className="m-0 pt-2"
                      style={{ fontSize: ".9rem", paddingLeft: 79 }}
                    >
                      Continue with Google
                    </p>
                  </div>
                </LoginSocialGoogle>
                {/* google login end */}
                {/* Apple login start */}
                <div
                  className="card mb-3"
                  id="apple"
                  style={{
                    border: "thin solid rgb(224, 224, 224)",
                    boxShadow: "none",
                    borderRadius: 5,
                    height: 40,
                    width: 280,
                    cursor: "pointer",
                    margin: "auto",
                  }}
                >
                  <img
                    className="topright"
                    src="../images/apple.png"
                    style={{
                      position: "absolute",
                      width: 24,
                      height: "auto",
                      top: 7,
                      left: 7,
                    }}
                  />
                  <p
                    className="m-0 pt-2"
                    style={{ fontSize: ".9rem", paddingLeft: 79 }}
                  >
                    Continue with Apple
                  </p>
                </div>
                {/* Apple login End */}

                <LoginSocialFacebook
                  appId="3071296896506159"
                  onResolve={(response) => {
                    console.log("new", response);
                    autoSubmit(response?.data?.email, response?.data?.id);
                  }}
                  onReject={(err) => {
                    console.log("new", err);
                  }}
                >
                  <div
                    className="card mb-3"
                    style={{
                      border: "thin solid rgb(224, 224, 224)",
                      boxShadow: "none",
                      borderRadius: 5,
                      height: 40,
                      width: 280,
                      cursor: "pointer",
                      margin: "auto",
                    }}
                  >
                    <img
                      src="../images/facebook.svg"
                      style={{
                        position: "absolute",
                        width: 24,
                        height: "auto",
                        top: 7,
                        left: 7,
                      }}
                    />
                    <p
                      className="m-0 pt-2"
                      style={{ fontSize: ".9rem", paddingLeft: 79 }}
                    >
                      Continue with Facebook
                    </p>
                  </div>
                </LoginSocialFacebook>
              </div>

              {customFieldVisible && (
                <>
                  {" "}
                  <p
                    className="paragraph mb-0 mt-3"
                    style={{ fontSize: 12, float: "right" }}
                  >
                    <p
                      className="aTags paragraph mb-0"
                      style={{ cursor: "pointer" }}
                      onClick={doPass}
                    >
                      Forgot your Password?
                    </p>
                  </p>
                  <div className="mt-5">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3 row">
                        <label
                          htmlFor="email"
                          className="col-3 col-form-label accountLabel"
                        >
                          Email
                        </label>
                        <div className="col-9">
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control accountInput"
                            id="email"
                            placeholder="Enter your Email"
                            required
                            style={{ borderColor: " #2f7f0e" }}
                          />
                        </div>
                      </div>

                      <div className="mb-3 row">
                        <label
                          htmlFor="password"
                          className="col-3 col-form-label accountLabel"
                        >
                          Password
                        </label>
                        <div className="col-9">
                          <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control accountInput"
                            id="password"
                            placeholder="Enter your Password"
                            required
                            style={{ borderColor: " #2f7f0e" }}
                          />
                        </div>
                      </div>

                      {/* <p
                    className="paragraph"
                    style={{ marginBottom: 0, fontSize: 12 }}
                  >
                    By Login, I confirm that I have read, understood, and agree
                    to the{" "}
                    <Link to="/rules" className="aTags paragraph">
                      General Rules
                    </Link>
                    ,{" "}
                    <Link to="/terms" className="aTags paragraph">
                      Terms & Conditions
                    </Link>
                    , and{" "}
                    <Link to="/policy" className="aTags paragraph">
                      Privacy Policy
                    </Link>
                    .
                  </p> */}
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btStandardFill p-0 mt-3"
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-0"></div>
      </div>
    </Layout>
  );
};
// <!-- for fb signin -->

export default Login;

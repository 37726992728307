import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import Swal from "sweetalert2";

const Nominate = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const [nomineeName, setNomineeName] = useState("");
  const [nomineeDescription, setNomineeDescription] = useState("");
  const [reasonForNomination, setReasonForNomination] = useState("");
  const [nomineeContactPerson, setNomineeContactPerson] = useState("");
  const [nomineeEmail, setNomineeEmail] = useState("");
  const [nomineeWebsite, setNomineeWebsite] = useState("");

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/v1/auth/nominate", {
        nomineeName,
        nomineeDescription,
        reasonForNomination,
        nomineeContactPerson,
        nomineeEmail,
        nomineeWebsite,
      });
      if (res && res.data.success) {
        Swal.fire({
          text: "Your nomination is submitted",
          confirmButtonColor: "#DD8A37",
          customClass: {
            confirmButton: "btt",
          },
        });
        setNomineeName("");
        setNomineeDescription("");
        setReasonForNomination("");
        setNomineeContactPerson("");
        setNomineeEmail("");
        setNomineeWebsite("");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title={"Nominate a Cause"}>
      <div className="row w-100 m-auto">
        <div className="col-lg-6">
          <div className="topSection">
            <div className="container-fluid" style={{ lineHeight: "1.3" }}>
              <p className="headingA">NOMINATE A CAUSE</p>
              <p className="paragraph">
                As part of our commitment to bear fruit for everyone worldwide,
                we would like to invite you to nominate a cause that you are
                passionate about or you feel requires our support.
              </p>

              {auth?.token ? (
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label
                      htmlFor="nomineeName"
                      className="form-label paragraph"
                    >
                      Nominee Name *
                    </label>
                    <input
                      type="text"
                      className="form-control nominateInput"
                      id="nomineeName"
                      aria-describedby="nomineeNameHelp"
                      value={nomineeName}
                      onChange={(e) => setNomineeName(e.target.value)}
                      autoComplete="off"
                      required
                    />
                    {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                  </div>

                  <div class="mb-3">
                    <label
                      for="nomineeDescription"
                      class="form-label paragraph"
                    >
                      Nominee Description *
                    </label>
                    <textarea
                      class="form-control nominateInput"
                      id="nomineeDescription"
                      rows="3"
                      value={nomineeDescription}
                      onChange={(e) => setNomineeDescription(e.target.value)}
                      autoComplete="off"
                      required
                    ></textarea>
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="reasonForNomination"
                      className="form-label paragraph"
                    >
                      Reason for Nomination *
                    </label>
                    <textarea
                      type="text"
                      className="form-control nominateInput"
                      id="reasonForNomination"
                      aria-describedby="reasonForNominationHelp"
                      rows="3"
                      value={reasonForNomination}
                      onChange={(e) => setReasonForNomination(e.target.value)}
                      autoComplete="off"
                      required
                    ></textarea>
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="nomineeContactPerson"
                      className="form-label paragraph"
                    >
                      Nominee Contact Person
                    </label>
                    <input
                      type="text"
                      className="form-control nominateInput"
                      id="nomineeContactPerson"
                      aria-describedby="nomineeContactPersonHelp"
                      value={nomineeContactPerson}
                      onChange={(e) => setNomineeContactPerson(e.target.value)}
                      autoComplete="off"
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="nomineeEmail"
                      className="form-label paragraph"
                    >
                      Nominee Email
                    </label>
                    <input
                      type="email"
                      className="form-control nominateInput"
                      id="nomineeEmail"
                      aria-describedby="nomineeEmailHelp"
                      value={nomineeEmail}
                      onChange={(e) => setNomineeEmail(e.target.value)}
                      autoComplete="off"
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="nomineeWebsite"
                      className="form-label paragraph"
                    >
                      Nominee Website
                    </label>
                    <input
                      type="text"
                      className="form-control nominateInput"
                      id="nomineeWebsite"
                      aria-describedby="nomineeWebsiteHelp"
                      value={nomineeWebsite}
                      onChange={(e) => setNomineeWebsite(e.target.value)}
                      autoComplete="off"
                    />
                  </div>

                  <button type="submit" className="btn btStandardFill p-0 mt-2">
                    Submit
                  </button>
                </form>
              ) : (
                <p
                  className="paragraph"
                  style={{ color: "#DD8A37", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/login", {
                      state: "/nominate",
                    });
                  }}
                >
                  Log in to view the Form
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6"></div>
      </div>
    </Layout>
  );
};

export default Nominate;

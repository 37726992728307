import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Policy from "./pages/Policy";
import Pagenotfound from "./pages/Pagenotfound";
import Participation from "./pages/Participation";
import Terms from "./pages/Terms";
import Rules from "./pages/HowItWorks";
import Referrals from "./pages/Referrals";
import Account from "./pages/Account";
import Faq from "./pages/Faq";
import Nominate from "./pages/Nominate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login";
import PrivateRoute from "./components/Routes/Private";
import AdminRoute from "./components/Routes/AdminRoute";
import Profile from "./pages/Profile";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import ConstructionPage from "./pages/ConstructionPage";
import React, { useEffect, useState } from "react";
import EditAccount from "./pages/EditAccount";
import HowItWorks from "./pages/HowItWorks";
import EULA from "./pages/EULA";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import UnsubscribePage from "./pages/UnsubscribePage";
import CampaignResults from "./pages/CampaignResults";
import ScrollToTop from "./pages/ScrollToTop";
import AdminPage from "./pages/AdminPage";
import NoAccessPage from "./pages/NoAccessPage";

function App() {
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         "https://bearfruitcampaigns.com/login-apple",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ key: "value" }),
  //         }
  //       );
  //       console.log("popopop", response);
  //       // const data = await response.json();
  //       // setAppleResponse(data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, []);
 

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<ConstructionPage />} />
        <Route path="/beta" element={<HomePage />} />
        <Route path="/referral/:value" element={<HomePage />} />
        {/* <Route path="/user" element={<PrivateRoute />}> */}
        {/* <Route path="/1001" element={<Navigate replace to={abc} />} /> */}
        <Route path="/profile" element={<Profile />} />
        {/* </Route> */}
        <Route path="/create-account" element={<Account />} />
        <Route path="/edit-account" element={<EditAccount />} />
        <Route path="/login" element={<Login />} />
        <Route path="/nominate" element={<Nominate />} />
        <Route path="/participation" element={<Participation />} />
        {/* <Route path="/participation" element={<PrivateRoute />}>
          <Route path="user" element={<Participation />} />
        </Route> */}
        <Route path="/admintobtek" element={<AdminRoute />}>
          <Route path="admin" element={<AdminPage />} />
        </Route>
        <Route path="/terms" element={<Terms />} />
        <Route path="/how-we-operate" element={<HowItWorks />} />
        <Route path="/eula" element={<EULA />} />
        <Route path="/referrals" element={<Referrals />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/about" element={<About />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route
          path="/do-password-reset/:value"
          element={<ResetPasswordPage />}
        />
        <Route path="/unsubscribe/:value" element={<UnsubscribePage />} />
        <Route path="/unsubscribe" element={<UnsubscribePage />} />
        <Route path="/campaign-results" element={<CampaignResults />} />
        <Route path="/no-access" element={<NoAccessPage />} />
        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
}

export default App;

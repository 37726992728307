import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import { useCart } from "../context/cart";
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

const CheckoutPage = () => {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  // total price
  const totalPrice = () => {
    try {
      let total = 0;
      cart?.map((item) => {
        total = total + item.contributeAmount * item.quantity;
      });
      return total.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } catch (error) {
      console.log(error);
    }
  };

  

  //handle payments
  const handlePayment = async () => {
    try {
      // let basket = [];
      // cart.map((i) => {
      //   const newAmount = i.contributeAmount * i.quantity;
      //   const oldAmount = getCodeAmount(i.code);
      //   const tot = newAmount + oldAmount;
      //   console.log("tot", tot);
      //   if (tot > 1500) {
      //     basket.push(i.code);
      //   }
      // });
      // console.log("bbbbaaaaaaaaaaaaaaaaaa", basket);
      // if (basket.length === 0) {
        const waitSeconds = () =>
          new Promise((resolve) => setTimeout(resolve, 600));
        setLoading(true);
        const { data } = await axios.post("/api/v1/product/payment", {
          cart,
          result: "success",
        });
        setLoading(false);
        setCart([]);
        localStorage.removeItem("cart");

        navigate("/participation");
        await waitSeconds();
        toast.success("Payment Success");
      // } else {
      //   const csvString = basket.join(", ");
      //   Swal.fire({
      //     text: `Your limit exceeds in : ${csvString}`,
      //     confirmButtonColor: "#DD8A37",
      //     customClass: {
      //       confirmButton: "btt",
      //     },
      //   });
      //   navigate("/cart");
      // }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Payment Failed");
    }
  };

  //handle payment failure
  const handlePaymentFailure = async () => {
    try {
      toast.error("Payment Failed - From Payment Gateway");
    } catch (error) {
      console.log(error);
      toast.error("Payment Failed");
    }
  };

 

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* <h1 className="text-center bg-light p-2 mb-1">
              {`Hello ${auth?.token && auth?.user?.firstName}`}
            </h1> */}
            <h4 className="text-center">Payment Gateway</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row mb-2 p-4 m-2 card flex-row">
              <div className="col justify-content-center text-center">
                <p className="mb-0">Amount: {totalPrice()}</p>
                <br />
                <button
                  className="btn btStandardFill"
                  style={{ padding: 0 }}
                  onClick={handlePayment}
                  disabled={loading || !auth?.user}
                >
                  {loading ? "Processing.." : "Success"}
                </button>
                <br />
                <br />
                <button
                  className="btn btStandardFillGray"
                  onClick={handlePaymentFailure}
                  disabled={loading || !auth?.user}
                >
                  Failure
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CheckoutPage;

import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { useAuth } from "../context/auth";

const Contact = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dd = queryParams.get("dd");
  let custom = "Select";
  let customValue = "";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [auth] = useAuth();

  if (dd === "edit") {
    custom = "Edit Account Details";
    customValue = "Edit Account Details";
  }

  //GET PROFILE DETAILS
  const getProfileDetails = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-profile");
      setName(data?.profile?.firstName);
      setEmail(data?.profile?.email);
    } catch (error) {
      console.log("error at profile page", error);
    }
  };

  const options = [
    { value: "General Inquiry", label: "General Inquiry" },
    { value: "Technical Issue", label: "Technical Issue" },
    { value: "Navigation & Use", label: "Navigation & Use" },
  ];

  const handleChange = (subject) => {
    setSubject(subject);
  };

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(name, email, message, subject.label);
      const res = await axios.post("/api/v1/auth/contact-us", {
        name,
        email,
        subject: subject.label,
        message,
      });
      if (res && res.data.success) {
        Swal.fire({
          text: "Thanks for contacting us",
          confirmButtonColor: "#DD8A37",
          customClass: {
            confirmButton: "btt",
          },
        });
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getProfileDetails();
    }
  }, [auth?.token]);

  return (
    <Layout title={"Contact"}>
      <div
        className="profile-container"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <p className="headingA">CONTACT US</p>
        <div className="row">
          <div className="column" style={{ textAlign: "center" }}>
            <form onSubmit={handleSubmit}>
              {/* <label htmlFor="subject" style={{ float: "left" }}>
                Subject
              </label>
              <select
                type="text"
                id="subject"
                name="subject"
                style={{
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: "2.5rem",
                  color: "black",
                }}
                required
              >
                <option value={customValue}>{custom}</option>
                <option value="General Inquiry">General Inquiry</option>
                <option value="Technical Issue">Technical Issue</option>
                <option value="Navigation & Use">Navigation &amp; Use</option>
              </select> */}

              {/* SUBJECT */}
              <div className="mb-3 row">
                <label
                  htmlFor="subject"
                  className="col-3 col-form-label accountLabel m-auto"
                  style={{ textAlign: "left" }}
                >
                  Subject
                </label>
                <div className="col-9 m-auto">
                  {/* <select
                    type="text"
                    id="subject"
                    name="subject"
                    required
                    className="form-control accountInput p-auto mb-0"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    style={{
                      borderColor: " #2f7f0e",
                    }}
                  >
                    <option value={customValue}>{custom}</option>
                    <option value="General Inquiry">General Inquiry</option>
                    <option value="Technical Issue">Technical Issue</option>
                    <option value="Navigation & Use">
                      Navigation &amp; Use
                    </option>
                  </select> */}

                  <Select
                    id="subject"
                    name="subject"
                    required
                    value={subject}
                    onChange={handleChange}
                    options={options}
                    placeholder="Select"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        background: "#fff",
                        borderColor: state.isFocused ? "#2F7F0E" : "#2F7F0E",
                        boxShadow: state.isFocused
                          ? "none"
                          : provided.boxShadow,
                        minHeight: "35px",
                        height: "35px",
                        fontSize: 14,
                        textAlign: "left",
                        fontWeight: 500,
                        borderRadius: "5px",
                        "&:hover": {
                          borderColor: "#2F7F0E",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        background:
                          state.isFocused || state.isActive
                            ? "#999999"
                            : "#fff",
                        color: "black",
                        "&:hover": {
                          background: "#999999",
                        },
                      }),
                    }}
                  />
                </div>
              </div>

              {/* <label htmlFor="message" style={{ float: "left" }}>
                Message
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Write the message"
                style={{ height: 170, borderRadius: 5 }}
                required
                defaultValue={""}
              /> */}

              <div className="mb-3 row">
                <label
                  htmlFor="message"
                  className="col-3 col-form-label accountLabel m-auto"
                  style={{ textAlign: "left" }}
                >
                  Message
                </label>
                <div className="col-9 m-auto">
                  <textarea
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="form-control accountInput p-auto"
                    id="message"
                    name="message"
                    placeholder="Your Message"
                    autoComplete="off"
                    required
                    rows={4}
                    style={{
                      marginBottom: 0,
                      borderColor: " #2f7f0e",
                    }}
                  ></textarea>
                </div>
              </div>

              {/* <label htmlFor="fname" style={{ float: "left" }}>
                Name
              </label>
              <input
                type="text"
                id="fname"
                name="firstname"
                placeholder="Your name"
                defaultValue=""
                required
              /> */}
              {auth?.token ? (
                ""
              ) : (
                <>
                  {" "}
                  <div className="mb-3 row">
                    <label
                      htmlFor="name"
                      className="col-3 col-form-label accountLabel m-auto"
                      style={{ textAlign: "left" }}
                    >
                      Name
                    </label>
                    <div className="col-9 m-auto">
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control accountInput p-auto"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        autoComplete="off"
                        required
                        style={{
                          marginBottom: 0,
                          borderColor: " #2f7f0e",
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="email"
                      className="col-3 col-form-label accountLabel m-auto"
                      style={{ textAlign: "left" }}
                    >
                      Email
                    </label>
                    <div className="col-9 m-auto">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control accountInput p-auto"
                        id="email"
                        name="email"
                        placeholder="Your Email"
                        autoComplete="off"
                        required
                        style={{
                          marginBottom: 0,
                          borderColor: " #2f7f0e",
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* <label htmlFor="email" style={{ float: "left" }}>
                Email
              </label>
              <br />
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Your Email"
                defaultValue=""
                required
              /> */}
              <input type="text" id="date" hidden />
              <input type="text" id="myBreaksId" defaultValue="" hidden />
              <input
                type="submit"
                id="submit"
                style={{ width: "7.3rem" }}
                defaultValue="Submit"
              />
            </form>
            <div style={{ textAlign: "center" }}>
              <p id="status" style={{ display: "none" }} />
            </div>
          </div>
        </div>
        

        {/* fb and instagram */}
        <p
          style={{
            color: "black",
            fontSize: ".65rem",
            paddingTop: "1.4rem",
            cursor: "pointer",
            marginBottom: 0,
            display:"flex"
          }}
          className="float-left"
        >
          Follow us&nbsp;&nbsp;
          <Link to="https://www.facebook.com/TOBTEKofficial">
            <img
              src="../../images/facebook.svg"
              alt="facebook"
              style={{ width: "1.5rem", height: "auto" }}
            />
          </Link>
          &nbsp;&nbsp;
          <Link to="https://www.instagram.com/tobtekofficial/">
            <img
              src="../../images/instagram.svg"
              alt="instagram"
              style={{ width: "1.5rem", height: "auto" }}
            />
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default Contact;

import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useAuth } from "../context/auth";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import { useCart } from "../context/cart";

const Profile = () => {
  const [auth, setAuth] = useAuth();
  const [profile, setProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [manageAccount, setManageAccount] = useState(false);
  const navigate = useNavigate();
  const [cart, setCart] = useCart();

  //GET PROFILE DETAILS
  const getProfileDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get("/api/v1/product/get-profile");
      setProfile(data.profile);
      setIsLoading(false);
    } catch (error) {
      console.log("error at profile page", error);
    }
  };

  const handleLogout = () => {
    Swal.fire({
      //title: "Are you sure?",
      text: "Confirm Logout",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD8A37",
      cancelButtonColor: "#666666",
      confirmButtonText: "Log Out",
      // buttonsStyling: false,
      customClass: {
        confirmButton: "btt",
        cancelButton: "btt",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setAuth({
          ...auth,
          user: null,
          token: "",
        });
        localStorage.removeItem("auth");
        setCart([]);
        localStorage.removeItem("cart");
        toast.success("Logout Successful");
        navigate("/beta");
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    if (auth?.token) {
      getProfileDetails();
      getParticipationDetails();
    }
  }, [auth?.token]);

  // DELETE PROFILE
  const deleteProfile = () => {
    try {
      if (manageAccount == true) {
        // const confirmation = window.confirm(
        //   "Are you sure you want to delete your account?"
        // );
        Swal.fire({
          //title: "Are you sure?",
          text: "Are you sure you want to delete your Account?",
          // icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD8A37",
          cancelButtonColor: "#666666",
          confirmButtonText: "Delete",
          // buttonsStyling: false,
          customClass: {
            confirmButton: "btt",
            cancelButton: "btt",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            const result = await axios.get("/api/v1/auth/delete-profile");
            if (result.data.success === true) {
              console.log(result.data.message);
              setAuth({
                ...auth,
                user: null,
                token: "",
              });
              localStorage.removeItem("auth");
              setCart([]);
              localStorage.removeItem("cart");
              Swal.fire({
                text: result.data.message,
                confirmButtonColor: "#DD8A37",
                customClass: {
                  confirmButton: "btt",
                },
              });
              navigate("/beta");
            } else {
              console.log(result.data.message);
              toast(result.data.message);
            }
          }
        });
      } else {
        Swal.fire({
          text: "You cannot delete your account until all Campaigns you participated in are complete and closed",
          confirmButtonColor: "#DD8A37",
          customClass: {
            confirmButton: "btt",
          },
        });
      }
    } catch (error) {
      console.log("error at profile deletion", error);
    }
  };

  // EDIT PROFILE
  const editProfile = async () => {
    try {
      if (manageAccount == true) {
        navigate("/edit-account");
      } else {
        Swal.fire({
          text: "You cannot edit your account until all Campaigns you participated in are complete and closed",
          confirmButtonColor: "#DD8A37",
          customClass: {
            confirmButton: "btt",
          },
        });
      }
    } catch (error) {
      console.log("error at profile editing", error);
    }
  };

  // FOR CHECKING WHEATHER A PARTICIPANT OR NOT
  const getParticipationDetails = async () => {
    try {
      const { data } = await axios.get("/api/v1/auth/orders");

      const productsMap = new Map();

      data.orders.forEach((order) => {
        order.products.forEach((item) => {
          const { product, quantity } = item;
          if (productsMap.has(product)) {
            productsMap.set(product, productsMap.get(product) + quantity);
          } else {
            productsMap.set(product, quantity);
          }
        });
      });

      const productElements = [];
      productsMap.forEach((quantity, product) => {
        const productDetails = data.products.find((p) => p._id === product);
        const st = productDetails.status === "Completed" ? "completed" : "";
        if (st === "") productElements.push(productDetails.initiative);
      });
      console.log("lllllll", productElements);
      if (productElements.length == 0) {
        setManageAccount(true);
      } else setManageAccount(false);
    } catch (error) {
      console.log("error at fetching participation", error);
    }
  };

  return (
    <Layout title={"Profile"}>
      <div className="profile-container">
        {/* <h1 className="profile-heading">Profile</h1> */}
        <div className="profile-info" style={{ paddingBottom: 42 }}>
          <p className="paragraph" style={{ fontSize: 12, float: "right" }}>
            <p
              className="aTags paragraph"
              style={{ cursor: "pointer" }}
              onClick={handleLogout}
            >
              Log Out
            </p>
          </p>

          <h5 style={{ paddingTop: 40, textTransform: "capitalize" }}>
            {profile?.firstName} {profile?.lastName}
          </h5>

          <table>
            <tr>
              <td>Raffle ID</td>
              <td>
                {isLoading && <Skeleton height="16px" width="160px" />}
                {profile?.raffleId}
              </td>
            </tr>
            {/* <tr>
              <td> Email</td>
              <td>
                {isLoading && <Skeleton height="16px" width="160px" />}
                {profile?.email}
              </td>
            </tr> */}
             <tr>
              <td>Email</td>
              <td>
                {isLoading && <Skeleton height="16px" width="160px" />}
                {profile?.email}
              </td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>
                {isLoading && <Skeleton height="16px" width="160px" />}
                {profile?.phone}
              </td>
            </tr>
            {profile?.whatsapp && (
              <tr>
                <td>Whatsapp</td>
                <td>
                  {isLoading && <Skeleton height="16px" width="160px" />}
                  {profile?.whatsapp}
                </td>
              </tr>
            )}
            <tr>
              <td>DOB</td>
              <td>
                {isLoading && <Skeleton height="16px" width="160px" />}
                {profile?.dob ? formatDate(profile?.dob) : ""}
              </td>
            </tr>
            <tr>
              <td>Nationality&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              <td>
                {isLoading && <Skeleton height="16px" width="160px" />}
                {profile?.nationality}
              </td>
            </tr>
            <tr>
              <td>Residence</td>
              <td>
                {isLoading && <Skeleton height="16px" width="160px" />}
                {profile?.residence}
              </td>
            </tr>
          </table>

          <p
            className="paragraph mb-4"
            style={{ fontSize: 12, float: "right" }}
          >
            <p
              onClick={editProfile}
              className="aTags paragraph"
              style={{ cursor: "pointer" }}
            >
              Edit
            </p>
          </p>
        </div>

        <div>
          <br />
          <p
            className="pr-1"
            onClick={deleteProfile}
            style={{
              color: "#FF0000",
              fontSize: ".65rem",
              textDecoration: "none",
              float: "right",
              cursor: "pointer",
            }}
          >
            Delete Account
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;

import React from "react";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  return (
    <Layout title={"How We Operate"}>
      <div id="howIt" className="topSection">
        <div className="container-fluid" style={{ lineHeight: "1.3" }}>
          <p className="headingA">How We Operate</p>
          <p className="paragraph">
            Welcome to Bear Fruit Campaigns, where your Chance to Win, is their
            Chance for Hope!
          </p>
          <p className="paraHeadingPTag">Introduction:</p>
          <p className="paragraph">
            Bear Fruit Campaigns is an innovative online platform that combines
            the excitement of raffles with the opportunity to make a positive
            impact on various causes. Our platform brings together individuals
            who share a common goal of supporting charitable organizations while
            offering them a chance to win big rewards.
          </p>
          <p className="paraHeadingPTag">User Registration:</p>
          <p className="paragraph">
            Getting started is easy! Simply create an account with accurate
            information based on a government-issued document to unlock the full
            potential of Bear Fruit Campaigns. It is important to provide
            precise details, as we require accurate information for account
            verification purposes. Please note that once a raffle is drawn and a
            winner is chosen, the winner will be required to verify their
            account by presenting a government-issued document that matches
            their account information. Failure to do so will result in the
            forfeiture of the reward, and a redraw will be conducted to
            determine a new winner.
          </p>
          <p className="paragraph">
            Please be aware that account information cannot be edited while you
            are participating in an ongoing campaign. Editing of account details
            is only allowed once the campaign is complete. This ensures the
            integrity of the raffle draw and maintains fairness throughout the
            process.
          </p>
          <p className="paragraph">
            Registration ensures a seamless experience and allows you to track
            your contributions, referrals, and raffle entries.
          </p>
          <p className="paraHeadingPTag">Campaign Selection:</p>
          <p className="paragraph">
            Browse through our diverse range of campaigns, each supporting a
            different cause, featuring varying rewards, and offering different
            contribution levels. Choose the campaigns that resonate with you the
            most—whether you're passionate about a specific cause, enticed by
            the reward, or looking to contribute within your budget.
          </p>
          <p className="paraHeadingPTag">Referral-Based Campaigns:</p>
          <p className="paragraph">
            We have exciting referral-based campaigns that allow you to
            participate without financially contributing. By referring friends
            to the platform, you earn entries into the free campaigns. Each
            referral that participates by either referring someone else or
            contributing to a campaign earns you one entry. Spread the word and
            increase your chances of winning!
          </p>
          <p className="paraHeadingPTag">Raffle Draw:</p>
          <p className="paragraph">
            When a campaign reaches its completion, meaning all available
            entries are depleted, a fair and unbiased raffle draw takes place
            using RandomPicker.com. This trusted platform ensures the integrity
            of the draw, eliminating any possibilities of cheating.
          </p>
          <p className="paraHeadingPTag">Result Confirmation:</p>
          <p className="paragraph">
            You can easily check the results of each campaign by visiting our
            platform or RandomPicker.com. Additionally, you can confirm your
            entry into a specific raffle draw by typing your name and/or your
            Raffle ID on the search field on the draw results page on
            RandomPicker.com. Transparency and accountability are at the core of
            Bear Fruit Campaigns.
          </p>
          <p className="paraHeadingPTag">Reward Fulfilment:</p>
          <p className="paragraph">
            Once a winner is announced, they will be contacted by our team to
            verify their account. The winner will be requested to provide the
            necessary documentation for account verification. Additionally, to
            ensure transparency and authenticity, the winner must share one of
            their active social media accounts. This account should be a genuine
            and publicly accessible profile on platforms such as Facebook,
            Twitter, or Instagram. Alternatively, if the winner does not have an
            active social media account, they will be required to record a video
            of themselves announcing their win. This additional step ensures
            that everyone knows the winners are real and that the integrity of
            the process is upheld. Once the account is successfully verified and
            the authenticity requirement is met, the reward will be transferred
            to the winner accordingly. Due to international money transfer
            regulation complications, rewards are issued in a Stablecoin
            Cryptocurrency. Depending on availability at the time of transfer,
            rewards may be issued in USDT, USDC, or BUSD, which are all
            equivalent to the US dollar at a rate of 1:1.
          </p>
          <p className="paraHeadingPTag">Charitable Donations:</p>
          <p className="paragraph">
            Once a campaign concludes, the donation amount collected is directed
            to the charitable organization associated with the cause of that
            particular campaign. We are committed to making a real impact and
            contributing to the betterment of society. In instances where the
            charitable organization permits public disclosure of the donation,
            we will readily display the information on our platform,
            exemplifying transparency to our participants. Nevertheless, if the
            charity does not authorize public viewing of the donation, we kindly
            urge participants to reach out to us directly for verification,
            ensuring that the donation has been made for the campaigns in which
            they participated. We value open communication and are dedicated to
            providing reassurance and accountability regarding the allocation of
            funds.
          </p>
          <p className="paragraph">
            At Bear Fruit Campaigns, we believe in fostering trust and building
            strong relationships with our community, and that everyone can make
            a difference.
          </p>
          <p className="paragraph">
            Join our platform today, contribute to meaningful causes, and stand
            a chance to win remarkable rewards.
          </p>
          <p className="paragraph">
            Let's come together, create positive change, and bear the fruit of
            generosity!
          </p>
          <p className="paragraph" style={{ marginBottom: 0, fontSize: 12 }}>
            For further details read the{" "}
            <Link to="/faq" className="aTags paragraph">
              FAQ
            </Link>{" "}
            or{" "}
            <Link to="/contact" className="aTags paragraph">
              Contact Us
            </Link>
            .
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default HowItWorks;

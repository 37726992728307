import React from "react";
import Layout from "../components/Layout/Layout";

const Policy = () => {
  return (
    <Layout title={"Privacy Policy"}>
      <div className="topSection">
        <div className="container-fluid" style={{ lineHeight: "1.3" }}>
          <p className="headingA mb-0">Privacy Policy</p>
          <p className="paragraph" style={{ fontSize: 12 }}>
            Last updated on June 12, 2023
          </p>
          <p className="paragraph">
            This Privacy Policy describes how BearFruitCampaigns.com owned and
            operated by TOBTEK SRL ("we," "us," or "our") collects, uses,
            discloses, and protects the personal information of users ("you" or
            "your") when you access and use our platform. We are committed to
            protecting your privacy and ensuring the security of your personal
            information. By accessing and using our platform, you expressly
            consent to the terms of this Privacy Policy.
          </p>
          <li type="1" className="paraHeading">
            Information We Collect
          </li>
          <p className="paragraph">
            1.1. Personal Information: When you create an account on our
            platform, we may collect personal information from you, including
            but not limited to your full name, email address, phone number, date
            of birth, nationality, and country of residence. We collect this
            information to facilitate your participation in raffle draws,
            process donations, and provide personalized services.
          </p>
          <p className="paragraph">
            1.2. Additional Information: In addition to personal information, we
            may collect additional information that you voluntarily provide to
            us, such as your preferred causes or charities for donation
            purposes. This information helps us tailor the platform and donation
            campaigns to your interests and preferences.
          </p>
          <p className="paragraph">
            1.3. Usage Information: We automatically collect certain information
            about your use of the platform, such as your IP address, browser
            type, device information, operating system, and usage patterns. This
            information is collected through cookies and similar technologies
            and is used to improve the functionality and performance of our
            platform.
          </p>
          <li type="1" className="paraHeading">
            Use of Information
          </li>
          <p className="paragraph">
            We may use the personal information we collect for the following
            purposes:
          </p>
          <ul>
            <li type="a" className="paragraph mt-3 ">
              Account Creation and Management: To create and manage your account
              on our platform, including verifying your identity and ensuring
              compliance with our Terms and Conditions.
            </li>
            <li type="a" className="paragraph mt-3">
              Raffle Draws and Donations: To process and facilitate your
              participation in raffle draws, including selecting winners and
              distributing rewards, as well as to process donations to charities
              or causes you have chosen to support.
            </li>
            <li type="a" className="paragraph mt-3">
              Communication: To communicate with you regarding platform updates,
              news, promotional offers, and other relevant information. We may
              contact you via email, phone, or other communication channels you
              have provided.
            </li>
            <li type="a" className="paragraph mt-3">
              Personalization: To personalize your experience on the platform,
              including recommending campaigns and causes that align with your
              interests and providing targeted content and advertisements.
            </li>
            <li type="a" className="paragraph mt-3">
              Research and Analysis: To conduct research and analysis to
              understand user preferences, improve our services, and develop new
              features and functionalities.
            </li>
            <li type="a" className="paragraph mt-3">
              Legal and Regulatory Compliance: To comply with applicable legal
              and regulatory requirements, including tax obligations, fraud
              prevention, and responding to legal requests from government
              authorities.
            </li>
            <li type="a" className="paragraph mt-3">
              Protection of Rights and Safety: To enforce our Terms and
              Conditions and protect the rights, privacy, safety, or property of
              our users or others. This includes preventing and detecting
              fraudulent or unauthorized activities on the platform.
            </li>
          </ul>
          <li type="1" className="paraHeading">
            Disclosure of Information
          </li>
          <p className="paragraph">
            We may disclose your personal information to third parties in the
            following circumstances:
          </p>
          <ul>
            <li type="a" className="paragraph mt-3">
              <b>Trusted Charities</b>: We may share your personal information
              with trusted charities or causes you have chosen to support
              through our platform. This may include sharing your name and
              donation details for transparency and reporting purposes.
            </li>
            <li type="a" className="paragraph mt-3">
              <b>Service Providers</b>: We may engage trusted service providers
              who assist us in operating our platform and providing our
              services. These service providers may have access to your personal
              information but are contractually obligated to maintain its
              confidentiality and only use it for the purposes specified by us.
            </li>
            <li type="a" className="paragraph mt-3">
              <b>Legal Obligations</b>: We may disclose your personal
              information to comply with applicable legal and regulatory
              requirements, including responding to court orders, subpoenas, or
              other legal requests. We may also disclose your information to
              protect our rights, privacy, safety, or property, or that of our
              users or others.
            </li>
            <li type="a" className="paragraph mt-3">
              <b>Business Transfers</b>: In the event of a merger, acquisition,
              or sale of all or a portion of our assets, your personal
              information may be transferred to the acquiring entity. We will
              ensure appropriate safeguards are in place to protect your
              personal information and the transferee will be bound by the terms
              of this Privacy Policy.
            </li>
          </ul>
          <p className="paraHeading">
            Collection of Government ID Copies and Verification Processes:
          </p>
          <p className="paragraph">
            We may, under certain circumstances, request users to provide copies
            of government-issued identification or participate in video calls or
            facial recognition for verification purposes. This process is solely
            intended to verify the identity of users in specific situations,
            such as winning a contest or for compliance with legal requirements.
            The collection and use of such information will be subject to the
            following:
          </p>
          <ul>
            <li type="a" className="paragraph mt-3">
              Purpose of Collection: The government ID copies or verification
              processes are collected solely for the purpose of verifying user
              identity in accordance with applicable laws, contest rules, or
              regulatory requirements.
            </li>
            <li type="a" className="paragraph mt-3">
              Storage and Protection: We take reasonable measures to securely
              store and protect the collected government ID copies or
              verification records. These documents will be kept confidential
              and will only be accessible to authorized personnel responsible
              for verification processes.
            </li>
            <li type="a" className="paragraph mt-3">
              Limited Use: The collected government ID copies or verification
              records will not be used for any purpose other than identity
              verification, as stated above. We will not share this information
              with any third parties unless required by law or with explicit
              consent from the user.
            </li>
            <li type="a" className="paragraph mt-3">
              Retention Period: We will retain the collected government ID
              copies or verification records for the duration necessary to
              fulfill the intended purpose and to comply with any legal or
              regulatory obligations. After this period, the records will be
              securely disposed of.
            </li>
          </ul>
          <li type="1" className="paraHeading">
            Data Security
          </li>
          <p className="paragraph">
            We take reasonable measures to protect your personal information
            from unauthorized access, loss, misuse, or alteration. We implement
            appropriate technical and organizational security measures to
            safeguard the confidentiality, integrity, and availability of your
            information. However, please note that no method of transmission
            over the internet or electronic storage is completely secure, and we
            cannot guarantee absolute security.
          </p>
          <li type="1" className="paraHeading">
            International Data Transfers
          </li>
          <p className="paragraph">
            Your personal information may be transferred to and processed in
            countries other than your own. By using our platform, you consent to
            the transfer, processing, and storage of your personal information
            in these countries, which may have different data protection laws
            than your country of residence. We will ensure that any
            international transfers of personal information comply with
            applicable data protection laws and that appropriate safeguards are
            in place to protect your information.
          </p>
          <li type="1" className="paraHeading">
            Your Rights
          </li>
          <p className="paragraph">
            You have certain rights regarding your personal information, which
            may include the right to access, update, correct, or delete your
            information. You may also have the right to object to or restrict
            certain processing activities or withdraw your consent. To exercise
            these rights, please contact us using the information provided in
            the "Contact Us" section below.
          </p>
          <li type="1" className="paraHeading">
            Third-Party Links
          </li>
          <p className="paragraph">
            Our platform may contain links to third-party websites or services.
            We are not responsible for the privacy practices or content of these
            third-party sites. We encourage you to review the privacy policies
            of those third parties before providing any personal information.
          </p>
          <li type="1" className="paraHeading">
            Changes to the Privacy Policy
          </li>
          <p className="paragraph">
            We reserve the right to update or modify this Privacy Policy at any
            time without prior notice. Any changes will be effective immediately
            upon posting the revised Privacy Policy on our platform. We
            encourage you to review this Privacy Policy periodically for any
            updates.
          </p>
          <p className="paraHeading">Cookie Policy</p>
          <p className="paragraph">
            This Cookie Policy explains how TOBTEK SRL ("we," "us," or "our")
            uses cookies and similar tracking technologies on our platform. By
            accessing and using our platform, you consent to the use of cookies
            in accordance with this Cookie Policy.
          </p>
          <li type="1" className="paraHeading">
            What are Cookies?
          </li>
          <p className="paragraph">
            Cookies are small text files that are placed on your device
            (computer, mobile device, or tablet) when you visit a website. They
            are widely used to enhance the functionality of websites and provide
            a more personalized experience for users. Cookies may store
            information about your browsing preferences, settings, and
            interactions with the website.
          </p>
          <li type="1" className="paraHeading">
            How We Use Cookies
          </li>
          <p className="paragraph">
            We use cookies for the following purposes:
          </p>
          <ul>
            <li type="a" className="paragraph mt-3">
              Essential Cookies: These cookies are necessary for the operation
              of our platform and enable you to navigate and use its features.
              They are essential for the proper functioning of the platform and
              cannot be disabled.
            </li>
            <li type="a" className="paragraph mt-3">
              Analytical Cookies: We use analytical cookies to collect
              information about how users interact with our platform, such as
              the number of visitors, pages visited, and the source of traffic.
              This information helps us analyze and improve the performance and
              usability of our platform.
            </li>
            <li type="a" className="paragraph mt-3">
              Functional Cookies: Functional cookies allow us to remember your
              preferences and settings on the platform, such as language
              preferences or customized options. They enhance your browsing
              experience by providing personalized features and content.
            </li>
            <li type="a" className="paragraph mt-3">
              Advertising Cookies: We may use advertising cookies to deliver
              relevant advertisements to you based on your interests and
              browsing behavior. These cookies track your interactions with our
              platform and other websites to provide targeted advertising.
            </li>
            <li type="a" className="paragraph mt-3">
              Use of Cookies: We utilize cookies to track and store referral
              links on users' browsers. These cookies allow us to attribute
              referrals and track participation in our raffle campaign
              accurately. The information stored in these cookies is encrypted
              and does not contain any personally identifiable information.
            </li>
          </ul>
          <li type="1" className="paraHeading">
            Third-Party Cookies
          </li>
          <p className="paragraph">
            11.1. We may also allow third parties, such as advertising networks
            or social media platforms, to place cookies on your device through
            our platform. These third parties may use cookies to collect
            information about your online activities across different websites
            and services.
          </p>
          <p className="paragraph">
            11.2. Please note that we do not have control over the cookies used
            by third parties, and their use is subject to their own privacy
            policies. We recommend reviewing the privacy policies of these third
            parties to understand their practices and how to opt-out of their
            tracking.
          </p>
          <li type="1" className="paraHeading">
            Your Cookie Choices
          </li>
          <p className="paragraph">
            12.1. By default, most web browsers are set to accept cookies.
            However, you can choose to block or delete cookies through your
            browser settings. Please note that blocking or deleting cookies may
            affect the functionality and performance of our platform and may
            limit your access to certain features.
          </p>
          <p className="paragraph">
            12.2. You can also opt-out of certain types of cookies, such as
            advertising cookies, by visiting the opt-out pages provided by
            relevant third-party advertisers or through the settings on your
            mobile device.
          </p>
          <li type="1" className="paraHeading">
            Updates to the Cookie Policy
          </li>
          <p className="paragraph">
            We may update or modify this Cookie Policy from time to time to
            reflect changes in our practices or applicable laws. Any changes
            will be effective upon posting the revised Cookie Policy on our
            platform.
          </p>
          <li type="1" className="paraHeading">
            Contact Us
          </li>
          <p className="paragraph">
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or the processing of your personal information,
            please contact us: legal@tobtek.com
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Policy;

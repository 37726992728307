import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { useAuth } from "../context/auth";
import { useCart } from "../context/cart";
import toast from "react-hot-toast";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import VisibilitySensor from "react-visibility-sensor";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";

const CampaignResults = () => {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  // const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const { value } = useParams();
  const saveReferralId = () => {
    localStorage.setItem("referralId", value);
  };

  // const products = [
  //   {
  //     initiative: "INITIATIVE - Charity Cause (example)",
  //     targetAmount: 450000,
  //     rewardAmount: 100000,
  //     contributeAmount: 0,
  //     totalEntries: 3000,
  //     status: "Completed",
  //     soldEntries: 3000,
  //     code: 0,
  //     completedDate: "2023-06-23T15:09:31.694+00:00",
  //   },
  // ];
  //get all products
  const getAllProducts = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-product");
      let d = data.products;
      d.sort((a, b) => {
        if (a.status === "Completed" && b.status !== "Completed") {
          return 1; // a should be placed after b
        } else if (a.status !== "Completed" && b.status === "Completed") {
          return -1; // a should be placed before b
        } else if (a.status === "Completed" && b.status === "Completed") {
          // Sort by completedDate in ascending order
          return new Date(b.completedDate) - new Date(a.completedDate);
        } else {
          return 0; // no sorting needed
        }
      });
      console.log("dd", d);
      setProducts(d);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const referFriend = async () => {
    try {
      toast("Please refer friends");
      navigate("/referrals");
    } catch (error) {
      console.log(error);
    }
  };

  const ImageSlider = ({ imageA, imageB }) => {
    const [currentImage, setCurrentImage] = useState(imageA);
    const [isImageA, setIsImageA] = useState(true);

    useEffect(() => {
      const timer = setInterval(
        () => {
          if (isImageA) {
            setCurrentImage(imageB);
          } else {
            setCurrentImage(imageA);
          }
          setIsImageA((prevIsImageA) => !prevIsImageA);
        },
        isImageA ? 4000 : 1000
      );

      return () => clearInterval(timer);
    }, [imageA, imageB, isImageA]);

    return (
      <img
        src={currentImage}
        className="img-responsive"
        style={{ width: "100%" }}
        alt="Initiative"
      />
    );
  };

  const formatNumber = (number) => {
    return number.toLocaleString("en-US");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const abPopup = () => {
    Swal.fire({
      text: "To be updated within 48h",
      confirmButtonColor: "#DD8A37",
      customClass: {
        confirmButton: "btt",
      },
    });
  };

  const winnerSocial = () => {
    console.log("social connection to winner");
  };

  return (
    <Layout title={"Bear Fruit Campaigns"}>
      <div className="topSection">
        <div className="container-fluid p-0" style={{ lineHeight: "1.3" }}>
          <p className="headingA">CAMPAIGN RESULTS</p>

          <p className="paragraph">
            Campaigns concluded are available below for Reward and Donation
            confirmations.
          </p>
          <p className="paragraph">
            To view raffle participant lists, or search your name on the entry
            list for campaigns you participated in, click on Raffle Results.
          </p>
          <p className="paragraph">
            To view donation confirmations, click on Charity Donation. Provided
            that we did not manage to obtain consent from a charity organization
            to publicly display acknowledgment of the donation, you may contact
            us for campaigns you participated in, and we would gladly present to
            you the confirmation.
          </p>
        </div>
      </div>

      {/* card section */}
      <div className="topSection">
        <div className="container-fluid p-0">
          <div className="row">
            {/* 1 */}
            {isLoading && (
              <>
                <p></p>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <Skeleton count={3} height="250px" width="100%" />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <Skeleton count={3} height="250px" width="100%" />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <Skeleton count={3} height="250px" width="100%" />
                </div>
              </>
            )}
            {products?.map((p) => {
              if (p.status === "Completed") {
                return (
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 imageDiv"
                    key={p._id}
                  >
                    <br />

                    <div className="extraText11">
                      <p
                        style={{ fontSize: "12px", cursor: "pointer" }}
                        onClick={p.winner ? winnerSocial : abPopup}
                      >
                        Social Media Account link or
                      </p>
                    </div>

                    <div className="extraText22">
                      <p
                        style={{ fontSize: "12px", cursor: "pointer" }}
                        onClick={p.winner ? winnerSocial : abPopup}
                      >
                        Video Recording link
                      </p>
                    </div>

                    {/* <div className="completionDate">
                  <p style={{ fontSize: "10px" }}>
                    Campaign Completed on: June 23, 2023
                  </p>
                </div> */}

                    <div className="extraText3">
                      <p
                        style={{
                          color: "#2F7F0E",
                          float: "left",
                          fontSize: 12,
                          fontWeight: 700,
                        }}
                      >
                        <span className="comp">
                          {p.winner ? (
                            <Link
                              to="https://app.randompicker.com/protocol/805124x57383"
                              target={"_blank"}
                              style={{
                                textDecoration: "none",
                                color: "#2F7F0E",
                              }}
                            >
                              RAFFLE RESULTS
                            </Link>
                          ) : (
                            <a
                              style={{
                                textDecoration: "none",
                                color: "#2F7F0E",
                                cursor: "pointer",
                              }}
                              onClick={abPopup}
                            >
                              RAFFLE RESULTS
                            </a>
                          )}
                        </span>
                      </p>
                    </div>

                    <div className="extraText4">
                      <p
                        style={{
                          color: "#2F7F0E",
                          float: "left",
                          fontSize: 12,
                          fontWeight: 700,
                        }}
                      >
                        <span className="comp">
                          <Link
                            to="/contact"
                            style={{ textDecoration: "none", color: "#2F7F0E" }}
                          >
                            CHARITY DONATION: ${formatNumber(p.targetAmount)}
                          </Link>
                        </span>
                      </p>
                    </div>

                    <div className="extraText5">
                      <h1 style={{ fontSize: "16px", color: "black" }}>
                        {p.winner ? p.winner : "To be updated within 48h"}
                      </h1>
                    </div>

                    {/* <div className="eg">
                  <h1 style={{ fontSize: "16px", color: "#DD8A37" }}>
                    (eg.)
                  </h1>
                </div> */}

                    <div
                      className="extraText6"
                      style={{ border: "thin solid #666666" }}
                    >
                      <img
                        src="../images/profile-demo.png"
                        style={{ width: 125, padding: 20 }}
                        alt="winner"
                      />
                    </div>

                    {/* DIGIT BY DIGIT RAFFLE AMOUNT */}
                    <div className="tagDollar44 comp">
                      <a
                        className="ora"
                        style={{
                          color: "white",
                          fontSize: 26,
                          backgroundColor: "#DD8A37",
                          padding: "0 1px",
                          margin: "0 1px 0 0",
                          float: "left",
                        }}
                      >
                        $
                      </a>
                      {p.rewardAmount
                        .toString()
                        .split("")
                        .map((digit, index) => (
                          <a key={index} className="oraDup">
                            {digit}
                          </a>
                        ))}
                      <br />

                      <a
                        className="ora"
                        style={{
                          color: "white",
                          fontSize: 12,
                          backgroundColor: "#DD8A37",
                          padding: "2px 2px",
                          margin: "3px 0",
                          float: "left",
                        }}
                      >
                        Winner
                      </a>
                    </div>

                    <div className="card cardMain" style={{ height: 230 }}>
                      {/* <div className="tagInitiative" style={{ paddingTop: 12 }}>
                    <p
                      style={{
                        color: "#DD8A37",
                        float: "left",
                        fontSize: 14,
                      }}
                    >
                      <span className="comp">
                        <b>{p.initiative}</b>
                      </span>
                    </p>
                    <p
                      style={{
                        color: "#666666",
                        float: "right",
                        fontSize: 12,
                      }}
                    >
                      BF-{p.code}i
                    </p>
                  </div> */}
                      <div className="tagInitiative" style={{ paddingTop: 12 }}>
                        <p
                          style={{
                            color: "#DD8A37",
                            marginBottom: 0,
                            fontSize: 14,
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                          className="comp"
                        >
                          <b>{p.initiative}</b>
                        </p>
                        <p
                          style={{
                            color: "#666666",
                            float: "left",
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          <span className="comp">
                            {p.completedDate
                              ? `Completed on ${formatDate(p.completedDate)}`
                              : ""}
                          </span>
                        </p>
                        <p
                          style={{
                            color: "#666666",
                            float: "right",
                            fontSize: 12,
                          }}
                        >
                          BF-{p.code}
                        </p>
                      </div>
                      {/* *********** */}
                    </div>
                  </div>
                );
              } else {
                // Return null for non-completed items
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CampaignResults;

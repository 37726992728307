import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useAuth } from "../context/auth";
import { useNavigate,useHistory } from "react-router-dom";
import { useCart } from "../context/cart";
import axios from "axios";
import Swal from "sweetalert2";
const CartPage = () => {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const navigate = useNavigate();
  const [oldProducts, setOldProducts] = useState([]);
  const [products, setProducts] = useState([]);

  // total price
  const totalPrice = () => {
    try {
      let total = 0;
      console.log("cccccccccccccccccccccart", cart);
      cart?.map((item) => {
        total = total + item.contributeAmount * item.quantity;
        if (item.quantity < 1) {
          quantity0(item._id);
        }
      });
      return total.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } catch (error) {
      console.log(error);
    }
  };

  //delete item
  const removeCartItem = (pid) => {
    try {
      let myCart = [...cart];
      let index = myCart.findIndex((item) => item._id === pid);
      myCart.splice(index, 1);
      setCart(myCart);
      localStorage.setItem("cart", JSON.stringify(myCart));
    } catch (error) {
      console.log(error);
    }
  };

  //delete item when quantity equals to zero
  const quantity0 = (itemId) => {
    try {
      setCart((prevCart) => {
        const updatedCart = prevCart.filter((item) => item._id !== itemId);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return updatedCart;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDecrement = (id) => {
    setCart((cart) => {
      const updatedCart = cart.map((newP) =>
        id === newP._id ? { ...newP, quantity: newP.quantity - 1 } : newP
      );

      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const handleIncrement = (id, q) => {
    const foundItem = products.find((item) => item._id === id);
    if (
      foundItem &&
      foundItem.status === "In Progress" &&
      foundItem.totalEntries - foundItem.soldEntries > q
    ) {
      setCart((cart) => {
        const updatedCart = cart.map((newP) =>
          id === newP._id ? { ...newP, quantity: newP.quantity + 1 } : newP
        );

        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return updatedCart;
      });
    } else {
      console.log("No more entries available");
      Swal.fire({
        text: "No more entries available",
        confirmButtonColor: "#DD8A37",
        customClass: {
          confirmButton: "btt",
        },
      });
    }
  };

  const handleQuantityChangeAuto = (id, quantity) => {
    console.log("iiiiii", id, quantity);
    setCart((cart) => {
      const updatedCart = cart.map((newP) =>
        id === newP._id ? { ...newP, quantity: quantity } : newP
      );

      localStorage.setItem("cart", JSON.stringify(updatedCart));
      console.log("updated ca", updatedCart);
      return updatedCart;
    });
  };

  // FOR GETTING THE PARTICIPATED ORDER FOR CHECKING THE LIMITS
  const getOrders = async () => {
    try {
      const { data } = await axios.get("/api/v1/auth/orders");

      const productsMap = new Map();

      data.orders.forEach((order) => {
        order.products.forEach((item) => {
          const { product, quantity } = item;
          if (productsMap.has(product)) {
            productsMap.set(product, productsMap.get(product) + quantity);
          } else {
            productsMap.set(product, quantity);
          }
        });
      });

      const productElements = [];
      productsMap.forEach((quantity, product) => {
        const productDetails = data.products.find((p) => p._id === product);
        const st = productDetails.status === "Completed" ? "completed" : "";
        productElements.push({
          code: productDetails.code,
          amount: productDetails.contributeAmount * quantity,
        });
      });

      setOldProducts(productElements);
      console.log("Biiiiiiiiiiiiiiiiiiig", productElements);
    } catch (error) {
      console.log(
        "error in getting the old participation at checkout the page",
        error
      );
    }
  };

  // old items already purchased : finding the amount
  const getCodeAmount = (code, a, b) => {
    const foundItem = oldProducts.find((item) => item.code === code);
    let z;
    if (foundItem) {
      z = foundItem?.amount + a * b;
    } else {
      z = a * b;
    }
    return z > 3000 ? true : false;
  };

  const getCodeAmount2 = (code) => {
    const foundItem = oldProducts.find((item) => item.code === code);
    return foundItem ? foundItem.amount : null;
  };

  const goToCheckout = () => {
    let basket = [];
    cart.map((i) => {
      const newAmount = i.contributeAmount * i.quantity;
      const oldAmount = getCodeAmount2(i.code);
      const tot = newAmount + oldAmount;
      console.log("tot", tot);
      if (tot > 3000) {
        basket.push(i.code);
      }
    });
    console.log("bbbbaaaaaaaaaaaaaaaaaa", basket);
    if (basket.length === 0) {
      Swal.fire({
        html: `<div style="text-align:left;font-size:14px">
              <p>Important:</p>
              <p>Please note that the following points are already covered in the <a href="/terms" target="_blank" style="color:#DD8A37;text-decoration:none">Terms & Conditions</a> that you agreed to upon creating your account. By restating them here, we aim to emphasize their significance without diminishing the importance of the other clauses within the Terms & Conditions.</p>
              <ul>
              <li>In the event that you are selected as a winner in any raffle draw, it is crucial that your account information is accurate and can be verified using a government-issued document. Note that account information cannot be edited if you are participating in an ongoing campaign, so if you need to amend any details do it now before proceeding.</li>
              <li>As part of our commitment to transparency, the names and pictures of winners will be publicly announced. Additionally, winners will be required to provide one of their active social media accounts, or a video recording acknowledging their win. This serves to demonstrate to other users that our winners are real individuals.</li>
              </ul>
              <p>Failure to provide the requirements above will result in the forfeiture of any rewards.</p>
              <p>For a more comprehensive understanding of our guidelines and operations, we highly recommend reviewing our detailed <a href="/how-we-operate" target="_blank" style="color:#DD8A37;text-decoration:none">How We Operate</a> and <a href="/faq" target="_blank" style="color:#DD8A37;text-decoration:none">FAQ</a> sections before finalizing your checkout.</p>
              <p>If you do not agree to the above terms, we kindly request that you refrain from proceeding with the checkout process, and delete your account.</p>
              </div>`,
        showCancelButton: true,
        confirmButtonColor: "#DD8A37",
        cancelButtonColor: "#666666",
        confirmButtonText: "I Agree",
        customClass: {
          confirmButton: "btt",
          cancelButton: "btt",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          // var link = document.createElement("a");
          // link.href = "https://buy.stripe.com/4gw29aa1nbPEgYE144";
          // link.target = "_blank";
          // var event = new MouseEvent("click", {
          //   view: window,
          //   bubbles: true,
          //   cancelable: true,
          // });
          // link.dispatchEvent(event);
          console.log(
            "Triggering In-Browser Purchase for totalAmount: " + totalPrice()
          );
          window.location.href = '/checkout';
          // window.webkit.messageHandlers.pay.postMessage(totalPrice());
        }
      });
    } else {
      Swal.fire({
        text: "You have exceeded your limit",
        confirmButtonColor: "#DD8A37",
        customClass: {
          confirmButton: "btt",
        },
      });
    }
  };

  //get all products
  const getAllProducts = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-product");
      setProducts(data.products);
      console.log("products", products, data.products);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshTheCart = async () => {
    const { data } = await axios.get("/api/v1/product/get-product");
    cart?.map((item) => {
      const foundItem = data.products.find((i) => i.code === item.code);
      let q;
      console.log("foundItem", foundItem);

      if (!foundItem || foundItem.status != "In Progress") {
        //delete
        quantity0(item._id);
      } else if (
        foundItem &&
        foundItem.status === "In Progress" &&
        foundItem.totalEntries - foundItem.soldEntries < item.quantity
      ) {
        q = foundItem.totalEntries - foundItem.soldEntries;
        handleQuantityChangeAuto(item._id, q);
      }
    });
  };

  useEffect(() => {
    if (auth?.token) {
      getOrders();
    }
  }, [auth?.token]);

  useEffect(() => {
    const fetchData = async () => {
      await getAllProducts();
      // await refreshTheCart();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData2 = async () => {
      await refreshTheCart();
      // await refreshTheCart();
    };

    fetchData2();
  }, []);

  let ios = false;

  // Define a JavaScript function that sends a request to Swift and handles the response
  function sendRequestToSwift(request, callback) {
    // Send the request to Swift
    window.webkit?.messageHandlers?.subscribe.postMessage(request);

    // Set up a listener to handle the response from Swift
    window.addEventListener("message", function (event) {
      if (event.data && event.data.fromSwift === true) {
        // Check if the event is a response from Swift
        var response = event.data.response;
        callback(response); // Call the provided callback with the response
      }
    });
  }

  useEffect(() => {
    // Example usage:
    sendRequestToSwift("subscribe", function (response) {
      // Handle the response from Swift
      console.log("Received response from Swift: " + response);
      if (response === "Subscription successful!") {
        ios = true;
      }
    });
  }, []);

  return (
    <Layout>
      <div className="container">
        {/* <div className="row">
          <div className="col-md-12"> */}
        {/* <h1 className="text-center bg-light p-2 mb-1">
              {`Hello ${auth?.token && auth?.user?.firstName}`}
            </h1> */}
        {/* <h4 className="text-center">
              {cart?.length
                ? `${cart.length} items in your Cart ${
                    auth?.token ? "" : "please login to checkout"
                  }`
                : " Your Cart Is Empty"}
            </h4> */}
        {/* </div>
        </div> */}

        <div className="row">
          <div className="col-md-8">
            <p className="headingA" style={{ marginLeft: 8 }}>
              CART
            </p>

            {cart?.map((newP) => (
              <div
                className="row m-2 card flex-row"
                style={{ padding: "24px 12px 18px 12px" }}
              >
                <div className="col-md-4">
                  <div className="card">
                    <div className="container cont">
                      <img
                        src={`/api/v1/product/product-photo/${newP._id}`}
                        className="img-responsive"
                        alt={newP.initiative}
                        width={"100%"}
                        height={"auto"}
                        style={{ borderRadius: "5px" }}
                      />
                      <img
                        src="images/transparent-layer.png"
                        className="img-responsive overlayImgCart"
                        alt="Initiative"
                        width={"100%"}
                        height={"auto"}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <p
                    style={{ color: "#DD8A37", marginBottom: 0, fontSize: 14 }}
                  >
                    {newP.initiative}
                  </p>
                  {/* <p>{p.description.substring(0, 30)}</p> */}

                  <p
                    style={{ color: "#666666", marginBottom: 5, fontSize: 14 }}
                  >
                    BF-{newP.code}
                  </p>
                  <p
                    style={{ color: "#666666", marginBottom: 5, fontSize: 14 }}
                  >
                    Raffle Reward: ${newP.rewardAmount}
                  </p>
                  <p
                    style={{ color: "#2F7F0E", marginBottom: 5, fontSize: 14 }}
                  >
                    Amount to Pay:{" "}
                    {getCodeAmount(
                      newP.code,
                      newP.contributeAmount,
                      newP.quantity
                    ) ? (
                      <span style={{ color: "red" }}>
                        ${newP.contributeAmount * newP.quantity}
                      </span>
                    ) : (
                      <span style={{ color: "green" }}>
                        ${newP.contributeAmount * newP.quantity}
                      </span>
                    )}
                  </p>
                  <p style={{ color: "red", marginBottom: 5, fontSize: 14 }}>
                    {getCodeAmount(
                      newP.code,
                      newP.contributeAmount,
                      newP.quantity
                    )
                      ? "You have exceed the $3,000 limit for this Campaign"
                      : ""}
                  </p>

                  <div style={{ display: "flex" }}>
                    {/* for increment and decrement */}
                    <div
                      className="input-group mb-2"
                      style={{ width: 110, marginRight: 10 }}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          handleDecrement(newP._id);
                        }}
                        className="input-group-text"
                        style={{
                          backgroundColor: "#2F7F0E",
                          borderColor: "#2F7F0E",
                          height: 28.8,
                        }}
                      >
                        -
                      </button>
                      <div
                        className="form-control text-center"
                        style={{
                          borderColor: "#2F7F0E",
                          height: 28.8,
                          padding: 0,
                        }}
                      >
                        {newP.quantity}
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          // quanti(newP.code, newP.quantity, newP._id)
                          handleIncrement(newP._id, newP.quantity);
                        }}
                        className="input-group-text"
                        style={{
                          backgroundColor: "#2F7F0E",
                          borderColor: "#2F7F0E",
                          height: 28.8,
                        }}
                      >
                        +
                      </button>
                    </div>

                    <button
                      className="btn btStandardFillGray"
                      onClick={() => removeCartItem(newP._id)}
                      style={{ width: "fit-content", padding: "0" }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1.8em"
                        width="1.8em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-4 text-center">
            <h2>Cart Summary</h2>
            <p>Total | Checkout | Payment</p>
            <hr />
            <h4>Total : {totalPrice()} </h4>
            {auth?.user ? (
              <>
                <div className="mb-3">
                  {cart?.length ? (
                    <button
                      className="btn btStandardFill"
                      style={{ padding: 0 }}
                      onClick={goToCheckout}
                    >
                      Checkout
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div className="mb-3">
                <button
                  className="btn btStandardFill pt-0"
                  onClick={() =>
                    navigate("/login", {
                      state: "/cart",
                    })
                  }
                  style={{ width: "170px" }}
                >
                  Log In to Checkout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CartPage;

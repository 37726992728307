import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useAuth } from "../context/auth";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Referrals = () => {
  const [auth, setAuth] = useAuth();
  const [users, setUsers] = useState([]);
  const [profile, setProfile] = useState([]);
  // const textToCopy = `https://bearfruitcampaigns.com/referral/${auth?.user?.uId}`;
  const navigate = useNavigate();

  //get all products
  const getAllReferrals = async () => {
    try {
      const { data } = await axios.get("/api/v1/auth/get-all-referrals");
      setUsers(data.users);
    } catch (error) {
      console.log(error);
    }
  };

  //GET PROFILE DETAILS
  const getProfileDetails = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-profile");
      setProfile(data.profile);
    } catch (error) {
      console.log("error at referral page in getting profile details", error);
    }
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://bearfruitcampaigns.com/referral/${profile?.raffleId}`
      );
      console.log("Text copied to clipboard");
      toast.success("Referral Link Copied");
    } catch (error) {
      console.log("Failed to copy text: ", error);
      toast.error("Failed to copy Referral Link");
    }
  };

  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Bear Fruit Campaigns",
          text: "Win Big and Make a Difference",
          url: `https://bearfruitcampaigns.com/referral/${profile?.raffleId}`,
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.log("Failed to share content: ", error);
        toast.error("Failed to share Referral Link");
      }
    } else {
      console.log("Web Share API not supported");
      toast.error("Sharing not supported in your browser");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    if (auth?.token) {
      getAllReferrals();
      getProfileDetails();
    }
  }, [auth?.token]);

  return (
    <Layout title={"Referrals"}>
      <div className="row w-100 m-auto">
        <div className="col-lg-6">
          <div className="topSection">
            <div className="container-fluid p-0" style={{ lineHeight: "1.3" }}>
              <p className="headingA">REFERRALS</p>
              <p className="paragraph">
                Get a chance to win the Fund a Cause Campaign Reward without
                financially contributing.
              </p>
              <p className="paragraph">
                All you have to do is share your link with people, and for each
                person that participates through your referral link, either by
                contributing or referring others, you earn a free entry in the
                campaign’s raffle.
              </p>
              <p className="paragraph">
                You can send your link directly to friends or post it publicly
                anywhere permissible. There is no limit to the number of
                referrals you make, each participating referral earns you one
                raffle entry. Spread the word and increase your
                chances of winning!
              </p>

              {auth?.token ? (
                <>
                  <p
                    className="paragraph"
                    style={{ marginBottom: 0, fontSize: 12 }}
                  >
                    Referrals are listed below
                  </p>
                  <br />

                  {/* Referral link */}
                  <p className="paragraph mb-0" style={{ color: "#DD8A37" }}>
                    Referral Link
                  </p>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Referral Link"
                      value={`https://bearfruitcampaigns.com/referral/${profile?.raffleId}`}
                      style={{ borderColor: "#2F7F0E" }}
                      autoComplete="off"
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={handleCopyClick}
                      style={{
                        borderColor: "#2F7F0E",
                        height: 38,
                        marginTop: 3,
                      }}
                    >
                      Copy
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={handleShareClick}
                      style={{
                        borderColor: "#2F7F0E",
                        height: 38,
                        marginTop: 3,
                      }}
                    >
                      Share
                    </button>
                  </div>
                  {/* Referral link end*/}

                  <br />
                  <table className="table table-hover table-bordered border-dark referral-table">
                    <thead>
                      <tr style={{ color: "#666666" }}>
                        <td scope="col">Referral’s Raffle ID</td>
                        <td scope="col">Participation Date</td>
                        <td scope="col">Reward Campaign</td>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.map((r) => (
                        <tr style={{ color: r.oldUser ? "red" : "#666666" }}>
                          <td>{r.friendsRaffleId}</td>
                          <td>
                            {r.campaignParticipationDate
                              ? formatDate(r.campaignParticipationDate)
                              : "N/A"}
                          </td>
                          <td>
                            {" "}
                            {r.rewardedCampaign
                              ? `BF-${r.rewardedCampaign}`
                              : "N/A"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <p
                  className="paragraph"
                  style={{ color: "#DD8A37", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/login", {
                      state: "/referrals",
                    });
                  }}
                >
                  Log In to view your Referral Link and referrals
                </p>
              )}

              {users?.filter((r) => r.oldUser).length > 0 && (
                <p className="paragraph">
                  Referrals in red are previously registered members, and their
                  participation will not be rewarded to you.
                </p>
              )}
            </div>
          </div>
        </div>{" "}
        <div className="col-lg-6"></div>
      </div>
    </Layout>
  );
};

export default Referrals;

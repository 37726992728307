import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";

const Faq = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const questions = [
    {
      question: "How can I participate in Bear Fruit Campaigns?",
      answer:
        "You must first create an account, then either refer friends or contribute in one of our campaigns to enter the raffle draws.",
    },
    {
      question:
        "How do I benefit from referring friends to Bear Fruit Campaigns?",
      answer:
        "When your friend participates in any of the campaigns by financially contributing or referring another friend, you earn a free entry to the Fund a Cause campaign raffle without financially contributing yourself. You earn one raffle entry for each participating friend that you refer.",
    },
    {
      question: "How much do I need to contribute to enter a raffle draw?",
      answer:
        "Each campaign has a different contribution amount, you can decide based on a campaign’s initiative or a campaign’s reward amount, whichever interest’s you most.",
    },
    {
      question: "How much can I win from a raffle draw?",
      answer:
        "Each campaign has a different reward amount, you can decide based on the amount you wish to contribute or hope to win.",
    },
    {
      question: "How can I increase my chances in winning?",
      answer:
        "You can either contribute in multiple campaigns or increase your contribution in a single campaign. Each contribution is equivalent to one entry into the campaign’s raffle draw. The maximum user limit per contribution campaigns is $3,000, however, you have unlimited entries into the free referral campaigns.",
    },
    {
      question: "When are reward raffle draws conducted?",
      answer:
        "Once a campaign’s initiative is achieved, and the available number of entries are depleted, the draw will be conducted, and you will be notified for any campaigns you participated in. Each campaign has a different initiative and number of entries available.",
    },
    {
      question: "How can I confirm my entry in a raffle draw?",
      answer:
        "Once the reward raffle is drawn, a link will be available on the Participation section which will direct you to the draw results on RandomPicker.com (an anti-cheating, unbiased raffle draw platform). There you can view the winner of the draw, in addition, you can search the entry list by typing your name or your Raffle ID in the search field to confirm your entry and weight (number of entries) in the draw.",
    },
    {
      question: "Is my name going to be publicly visible?",
      answer:
        "Only the winner’s name will be visible and publicly announced. All other participants’ names will be concealed.",
    },
    {
      question: "How can I confirm that real people are winning the rewards?",
      answer:
        "In order to claim a reward and ensure transparency and trust within our user community, the selected winner of a raffle must comply with the following verification requirements:" +
        "<ul><br><li type='1' >Account Verification: The winner must verify their account details using a government issued document, including but not limited to their name, email address, and contact information, as provided during the registration process. Failure to provide accurate and complete information may result in disqualification and forfeiture of the reward.</li>" +
        "<br><li type='1'>Social Media Account Sharing: The winner must publicly share at least one of their active social media accounts on the platform. This is to demonstrate to other users that real people are winning the raffles and to build trust within the community. The shared account(s) should be genuine and not created solely for the purpose of the raffle. The platform reserves the right to determine the authenticity and legitimacy of the shared social media account(s).</li>" +
        "<br><li type='1'>Video Confirmation (if no social media account available): If the winner does not have any active social media accounts, they must record a video of themselves stating their full name and confirming that they have won the raffle draw. This video may be used by the platform for promotional purposes and to validate the authenticity of the winner's claim.</li></ul>",
    },

    {
      question: "Why does my account information have to be accurate?",
      answer:
        "Due to our legal responsibilities, and to prevent fraudulent activities, in addition to instilling trust within our user community, we must ensure that winnings are transferred to verified individuals only.",
    },
    {
      question: "Do I have to verify my account once I register?",
      answer:
        "No, you are only required to verify your account if you win a raffle draw.",
    },
    {
      question: "What happens if I can’t verify my account?",
      answer:
        "If you cannot provide any government issued document that matches the information on your account within 7 days from the draw date, your reward will be forfeited and a redraw will be conducted to determine a new winner.",
    },
    {
      question: "Can someone else claim the reward on my behalf?",
      answer:
        "No, the reward will only be issued to the account holder’s information after the verification process.",
    },
    {
      question: "Can I participate in campaigns from anywhere in the world?",
      answer:
        "Yes, but you need to be aware of the laws of your country of residence and confirm whether your participation is permitted.",
    },
    {
      question: "How much time do I have to claim my reward?",
      answer:
        "You have 7 days from the draw date to claim your reward and verify your account and identity, after which the reward will be considered forfeited and a redraw will be conducted to determine a new winner.",
    },
    {
      question: "What currency will I receive my reward in?",
      answer:
        "Due to international money transfer regulation complications, rewards are issued in a Stablecoin Cryptocurrency. Depending on availability at the time of transfer, rewards may be issued in USDT, USDC, or BUSD, which are all equivalent to the US dollar at a rate of 1:1.",
    },
    {
      question: "Do you charge a fee for the transfer of a reward?",
      answer:
        "No, we do not charge anything, we initiate a transfer for the exact amount rewarded, but any transfer charges incurred by exchange platforms and wallets will be deducted from the reward amount.",
    },
    {
      question: "How much of each campaign is donated to charity?",
      answer:
        "Each campaign has a different formula as to how the funds are distributed. Our priority is the amount we donate on your behalf to charity, and that ranges between 40 to 50% of campaign contributions, which is indicated on each campaign. The rewards range between 20 to 30% of contributions, depending on each campaign, and around 25% cover banking and transaction fees, legal fees, marketing, advertising, and operational expenses.",
    },
    {
      question: "How can I confirm that donations are made to charities?",
      answer:
        "If we receive consent from the beneficiary charity to publicly display the donation made, then you will be able to view it on the Participation section. Otherwise, you can contact us and we would gladly present to you proof of donations made for campaigns that you participated in.",
    },
    {
      question: "Can I register more than one account?",
      answer:
        "No, only one account per individual is permitted. If we discover that a user has registered multiple accounts, we will terminate all accounts associated with the user, block the user from present and future participation, and we may also enforce a penalty.",
    },
    {
      question: "How can I change information on my account?",
      answer:
        "You can edit your account information through your account on the Platform as long as you are not participating in an ongoing campaign. If you win a raffle draw, the reward will be issued based on the information on the account when you participated, if your information is incorrect or you cannot verify your account, then your reward will be forfeited and a redraw will be conducted to determine a new winner.",
    },
    {
      question: "How can I reset my password?",
      answer:
        "You can either click on Forgot Password or change it from your account section on the platform.",
    },
    {
      question: "How do I close my account?",
      answer:
        "You can delete your account from your account section on the platform or contact us directly.",
    },
    {
      question: "What happens if I do not participate in any campaigns?",
      answer:
        "If an account is inactive for more than 365 days, the account will automatically be deleted.",
    },
    {
      question: "Is Bear Fruit Campaigns a legal entity?",
      answer:
        "Bear Fruit Campaigns is owned and operated by TOBTEK SRL, a duly incorporated entity in San Jose.",
    },

    // Add more questions and answers in the same format
  ];

  const handleQuestionClick = (index) => {
    if (index === selectedQuestion) {
      setSelectedQuestion(null); // Collapse the selected question if clicked again
    } else {
      setSelectedQuestion(index);
    }
  };

  return (
    <Layout title={"FAQ"}>
      <div className="topSection" style={{ padding: 10 }}>
        <div className="container-fluid" style={{ lineHeight: "1.3" }}>
          <p className="headingA">Frequently Asked Questions</p>

          {questions.map((question, index) => (
            <>
              <hr />
              <div
                className="position-absolute"
                style={{ right: 30, cursor: "pointer" }}
                onClick={() => handleQuestionClick(index)}
              >
                {selectedQuestion === index ? (
                  <svg
                    stroke="#DD8A37"
                    fill="#DD8A37"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"></path>
                  </svg>
                ) : (
                  <svg
                    stroke="#DD8A37"
                    fill="#DD8A37"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                  </svg>
                )}
              </div>
              <div key={index} className="faqQuestion">
                <p
                  className={`questionTitle ${
                    selectedQuestion === index ? "activ" : ""
                  }`}
                  onClick={() => handleQuestionClick(index)}
                  style={{
                    cursor: "pointer",
                    color: "#DD8A37",
                    fontWeight: 500,
                    fontSize: 14,
                    textAlign: "justify",
                    marginRight: 40,
                  }}
                >
                  {question.question}{" "}
                </p>

                {selectedQuestion === index && (
                  <p
                    className="paragraphFaq"
                    dangerouslySetInnerHTML={{ __html: question.answer }}
                  ></p>
                )}
              </div>
              {/* ****** */}
            </>
          ))}
          <hr />

          <p className="paragraph" style={{ marginBottom: 0, fontSize: 12 }}>
            For any other questions, feel free to{" "}
            <Link to="/contact" className="aTags paragraph">
              Contact Us
            </Link>
            .
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Faq;

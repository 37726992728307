import React from "react";
import Layout from "../components/Layout/Layout";

const EULA = () => {
  return (
    <Layout title={"Terms and Conditions"}>
      <div className="topSection">
        <div className="container-fluid" style={{ lineHeight: "1.3" }}>
          <p className="headingA mb-0">End-User License Agreement</p>
          <p className="paragraph" style={{ fontSize: 12 }}>
            Last updated on June 12, 2023
          </p>
          <p className="paragraph">
            Please read this End-User License Agreement carefully and accept all
            the terms and conditions set out below before purchasing,
            installing, and using any software. By purchasing any software, you
            agree to become the licensee of this EULA and provide consent to all
            the terms described below. Please do not purchase or request a
            purchase if you disagree to be bound by the terms of this EULA.
          </p>
          <p className="paraHeadingPTag">Interpretation</p>

          <p className="paragraph">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <p className="paraHeadingPTag">Definitions</p>
          <p className="paragraph">
            For the purposes of this End-User License Agreement:
          </p>
          <ul>
            <li className="paraList">
              "<b>Agreement</b>" means this End-User License Agreement that
              forms the entire agreement between You and the Company regarding
              the purchase, installing and use of any software.
            </li>
            <li className="paraList">
              "<b>Software</b>" means the software program provided by the
              Company downloaded by You.
            </li>
            <li className="paraList">
              "<b>Company</b>" (referred to as either "the Company", "We", "Us"
              or "Our" in this Agreement) refers to TOBTEK SRL.
            </li>
            <li className="paraList">
              "<b>Content</b>" refers to content such as text, images, or other
              information that can be posted, uploaded, linked to or otherwise
              made available by You, regardless of the form of that content.
            </li>
            <li className="paraList">
              "<b>Device</b>" means any device that can access the Software such
              as a computer, a phone or a digital tablet.
            </li>
            <li className="paraList">
              "<b>Third-Party Services</b>" means any services or content
              (including data, information, Software and other products
              services) provided by a third-party that may be displayed,
              included or made available by the Software.
            </li>
            <li className="paraList">
              "<b>You</b>" means the individual accessing or using the Software
              or the company, or other legal entity on behalf of which such
              individual is accessing or using the Software, as applicable.
            </li>
          </ul>
          <p className="paraHeadingPTag">Acknowledgment</p>
          <p className="paragraph">
            By accepting this Agreement, downloading or using the Software, You
            are agreeing to be bound by the terms and conditions of this
            Agreement. If You do not agree to the terms of this Agreement, do
            not download or do not use the Software.
          </p>
          <p className="paragraph">
            This Agreement is a legal document between You and the Company and
            it governs your use of the Software made available to You by the
            Company.
          </p>
          <p className="paragraph">
            Therefore, the Company is solely responsible for the Software and
            its content.
          </p>
          <p className="paragraph">
            The Software is licensed, not sold, to You by the Company for use
            strictly in accordance with the terms of this Agreement.
          </p>
          <p className="paraHeadingPTag">License</p>
          <p className="paraHeadingPTag">Scope of License</p>
          <p className="paragraph">
            The Company grants You a revocable, non-exclusive, non-transferable,
            limited license to download, install and use the Software strictly
            in accordance with the terms of this Agreement.
          </p>
          <p className="paragraph">
            You may only use the Software on a Device that You own or control
            and as permitted by the Software's terms and conditions.
          </p>
          <p className="paragraph">
            The license that is granted to You by the Company is solely for your
            personal, non-commercial purposes strictly in accordance with the
            terms of this Agreement.
          </p>

          <p className="paraHeadingPTag">License Restrictions</p>
          <p className="paragraph">
            You agree not to, and You will not permit others to:
          </p>
          <ul>
            <li className="paraList">
              License, sell, rent, lease, assign, distribute, transmit, host,
              outsource, disclose or otherwise commercially exploit the Software
              or make the Software available to any third party.
            </li>
            <li className="paraList">
              Remove, alter or obscure any proprietary notice (including any
              notice of copyright or trademark) of the Company or its
              affiliates, partners, suppliers or the licensors of the Software.
            </li>
          </ul>
          <p className="paraHeadingPTag">Intellectual Property</p>
          <p className="paragraph">
            The Software, including without limitation all copyrights, patents,
            trademarks, trade secrets and other intellectual property rights
            are, and shall remain, the sole and exclusive property of the
            Company.
          </p>
          <p className="paragraph">
            The Company shall not be obligated to indemnify or defend You with
            respect to any third party claim arising out of or relating to the
            Software. To the extent the Company is required to provide
            indemnification by applicable law, the Company, shall be solely
            responsible for the investigation, defense, settlement and discharge
            of any claim that the Software or your use of it infringes any third
            party intellectual property rights.
          </p>

          <p className="paraHeadingPTag">Modifications to the Software</p>
          <p className="paragraph">
            The Company reserves the right to modify, suspend or discontinue,
            temporarily or permanently, the Software or any service to which it
            connects, with or without notice and without liability to You.
          </p>
          <p className="paraHeadingPTag">Updates to the Software</p>
          <p className="paragraph">
            The Company may from time to time provide enhancements or
            improvements to the features/functionality of the Software, which
            may include patches, bug fixes, updates, upgrades and other
            modifications.
          </p>
          <p className="paragraph">
            Updates may modify or delete certain features and/or functionalities
            of the Software. You agree that the Company has no obligation to (i)
            provide any Updates, or (ii) continue to provide or enable any
            particular features and/or functionalities of the Software to You.
          </p>
          <p className="paragraph">
            You further agree that all updates or any other modifications will
            be (i) deemed to constitute an integral part of the Software, and
            (ii) subject to the terms and conditions of this Agreement.
          </p>
          <p className="paraHeadingPTag">Maintenance and Support</p>
          <p className="paragraph">
            The Company does not provide any maintenance or support for the
            download and use of the Software. To the extent that any maintenance
            or support is required by applicable law, the Company, shall be
            obligated to furnish any such maintenance or support.
          </p>
          <p className="paraHeadingPTag">Third-Party Services</p>
          <p className="paragraph">
            The Software may display, include or make available third-party
            content (including data, information, Software and other products
            services) or provide links to third-party websites or services.
          </p>
          <p className="paragraph">
            You acknowledge and agree that the Company shall not be responsible
            for any Third-party Services, including their accuracy,
            completeness, timeliness, validity, copyright compliance, legality,
            decency, quality or any other aspect thereof. The Company does not
            assume and shall not have any liability or responsibility to You or
            any other person or entity for any Third-party Services.
          </p>
          <p className="paragraph">
            You must comply with applicable Third parties' Terms of agreement
            when using the Software. Third-party Services and links thereto are
            provided solely as a convenience to You and You access and use them
            entirely at your own risk and subject to such third parties' Terms
            and conditions.
          </p>
          <p className="paraHeadingPTag">Term and Termination</p>
          <p className="paragraph">
            This Agreement shall remain in effect until terminated by You or the
            Company. The Company may, in its sole discretion, at any time and
            for any or no reason, suspend or terminate this Agreement with or
            without prior notice.
          </p>
          <p className="paragraph">
            This Agreement will terminate immediately, without prior notice from
            the Company, in the event that you fail to comply with any provision
            of this Agreement. You may also terminate this Agreement by deleting
            the Software and all copies thereof from your Device or from your
            computer.
          </p>
          <p className="paragraph">
            Upon termination of this Agreement, You shall cease all use of the
            Software and delete all copies of the Software from your Device.
          </p>
          <p className="paragraph">
            Termination of this Agreement will not limit any of the Company's
            rights or remedies at law or in equity in case of breach by You
            (during the term of this Agreement) of any of your obligations under
            the present Agreement.
          </p>
          <p className="paraHeadingPTag">Indemnification</p>
          <p className="paragraph">
            You agree to indemnify and hold the Company and its parents,
            subsidiaries, affiliates, officers, employees, agents, partners and
            licensors (if any) harmless from any claim or demand, including
            reasonable attorneys' fees, due to or arising out of your: (a) use
            of the Software; (b) violation of this Agreement or any law or
            regulation; or (c) violation of any right of a third party.
          </p>
          <p className="paraHeadingPTag">No Warranties</p>
          <p className="paragraph">
            The Software is provided to You "AS IS" and "AS AVAILABLE" and with
            all faults and defects without warranty of any kind. To the maximum
            extent permitted under applicable law, the Company, on its own
            behalf and on behalf of its affiliates and its and their respective
            licensors and service providers, expressly disclaims all warranties,
            whether express, implied, statutory or otherwise, with respect to
            the Software, including all implied warranties of merchantability,
            fitness for a particular purpose, title and non-infringement, and
            warranties that may arise out of course of dealing, course of
            performance, usage or trade practice. Without limitation to the
            foregoing, the Company provides no warranty or undertaking, and
            makes no representation of any kind that the Software will meet your
            requirements, achieve any intended results, be compatible or work
            with any other software, systems or services, operate without
            interruption, meet any performance or reliability standards or be
            error free or that any errors or defects can or will be corrected.
          </p>
          <p className="paragraph">
            Without limiting the foregoing, neither the Company nor any of the
            company's provider makes any representation or warranty of any kind,
            express or implied: (i) as to the operation or availability of the
            Software, or the information, content, and materials or products
            included thereon; (ii) that the Software will be uninterrupted or
            error-free; (iii) as to the accuracy, reliability, or currency of
            any information or content provided through the Software; or (iv)
            that the Software, its servers, the content, or e-mails sent from or
            on behalf of the Company are free of viruses, scripts, trojan
            horses, worms, malware, timebombs or other harmful components.
          </p>
          <p className="paragraph">
            Some jurisdictions do not allow the exclusion of certain types of
            warranties or limitations on applicable statutory rights of a
            consumer, so some or all of the above exclusions and limitations may
            not apply to You. But in such a case the exclusions and limitations
            set forth in this section shall be applied to the greatest extent
            enforceable under applicable law. To the extent any warranty exists
            under law that cannot be disclaimed, the Company, shall be solely
            responsible for such warranty.
          </p>
          <p className="paraHeadingPTag">Limitation of Liability</p>
          <p className="paragraph">
            Notwithstanding any damages that You might incur, the entire
            liability of the Company and any of its suppliers under any
            provision of this Agreement and your exclusive remedy for all of the
            foregoing shall be limited to the amount actually paid by You for
            the Software or through the Software or 100 USD if You haven't
            purchased anything through the Software.
          </p>
          <p className="paragraph">
            To the maximum extent permitted by applicable law, in no event shall
            the Company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever (including, but not
            limited to, damages for loss of profits, loss of data or other
            information, for business interruption, for personal injury, loss of
            privacy arising out of or in any way related to the use of or
            inability to use the Software, third-party software and/or
            third-party hardware used with the Software, or otherwise in
            connection with any provision of this Agreement), even if the
            Company or any supplier has been advised of the possibility of such
            damages and even if the remedy fails of its essential purpose.
          </p>
          <p className="paragraph">
            Some states/jurisdictions do not allow the exclusion or limitation
            of incidental or consequential damages, so the above limitation or
            exclusion may not apply to You.
          </p>
          <p className="paraHeadingPTag">Severability</p>
          <p className="paragraph">
            If any provision of this Agreement is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.
          </p>
          <p className="paraHeadingPTag">Waiver</p>
          <p className="paragraph">
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under this Agreement shall not
            affect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall the waiver of a breach
            constitute a waiver of any subsequent breach.
          </p>
          <p className="paraHeadingPTag">Product Claims</p>
          <p className="paragraph">
            The Company does not make any warranties concerning the Software. To
            the extent You have any claim arising from or relating to your use
            of the Software, the Company, is responsible for addressing any such
            claims, which may include, but not limited to: (i) any product
            liability claims; (ii) any claim that the Software fails to conform
            to any applicable legal or regulatory requirement; and (iii) any
            claim arising under consumer protection, or similar legislation.
          </p>
          <p className="paraHeadingPTag">United States Legal Compliance</p>
          <p className="paragraph">
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo, or that has
            been designated by the United States government as a "terrorist
            supporting" country, and (ii) You are not listed on any United
            States government list of prohibited or restricted parties.
          </p>
          <p className="paraHeadingPTag">Changes to this Agreement</p>
          <p className="paragraph">
            The Company reserves the right, at its sole discretion, to modify or
            replace this Agreement at any time. If a revision is material we
            will provide at least 30 days' notice prior to any new terms taking
            effect. What constitutes a material change will be determined at the
            sole discretion of the Company.
          </p>
          <p className="paragraph">
            By continuing to access or use the Software after any revisions
            become effective, You agree to be bound by the revised terms. If You
            do not agree to the new terms, You are no longer authorized to use
            the Software.
          </p>
          <p className="paraHeadingPTag">Governing Law</p>
          <p className="paragraph">
            The laws of Costa Rica, excluding its conflicts of law rules, shall
            govern this Agreement and your use of the Software. Your use of the
            Software may also be subject to other local, state, national, or
            international laws.
          </p>
          <p className="paraHeadingPTag">Entire Agreement</p>
          <p className="paragraph">
            The Agreement constitutes the entire agreement between You and the
            Company regarding your use of the Software and supersedes all prior
            and contemporaneous written or oral agreements between You and the
            Company.
          </p>
          <p className="paragraph">
            You may be subject to additional terms and conditions that apply
            when You use or purchase other Company's services, which the Company
            will provide to You at the time of such use or purchase.
          </p>
          <p className="paraHeadingPTag">Contact Us</p>
          <p className="paragraph">
            If you have any questions, concerns, or requests regarding this
            Agreement, please contact us: legal@tobtek.com
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default EULA;

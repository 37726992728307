import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Select from "react-select";
import { useAuth } from "../context/auth";
import Swal from "sweetalert2";

const ResetPasswordPage = () => {
  const [raffleId, setRaffleId] = useState("");
  const [originalRaffleId, setOriginalRaffleId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [dob, setDob] = useState("");
  const [nationality, setNationality] = useState("");
  const [residence, setResidence] = useState("");
  const [referralId, setReferralId] = useState("");
  const [rafError, setRafError] = useState("");
  const [passError, setPassError] = useState("");
  const [confirmPassError, setConfirmPassError] = useState("");
  const [dobError, setDobError] = useState("");
  const [nationalities, setNationalities] = useState([]);
  const [raffleIds, setRaffleIds] = useState([]);
  const [conditionDError, setConditionDError] = useState([]);
  const navigate = useNavigate();
  const [customFieldVisible, setCustomFieldVisible] = useState(false);
  const [emailPassVisible, setEmailPassVisible] = useState(true);
  const [signupOptionsVisible, setSignupOptionsVisible] = useState(true);
  const [validationType, setValidationType] = useState("otp");
  const [otp, setOtp] = useState("");
  const [auth, setAuth] = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idd = queryParams.get("i");
  const passs = queryParams.get("p");
  const [otherFieldsVisible, setOtherFieldsVisible] = useState(true);
  const [otpTextFieldVisible, setOtpTextFieldVisible] = useState(false);
  const [seconds, setSeconds] = useState(120);
  const [showResendButton, setShowResendButton] = useState(false);
  const [residenceError, setResidenceError] = useState("");
  const [profile, setProfile] = useState([]);

  const { value } = useParams();

  useEffect(() => {
    if (idd && passs) {
      autoSubmit(idd, passs, "apple");
    }
  }, []);

  const existingReferralId = localStorage.getItem("referralId");

  //GET PROFILE DETAILS
  const getProfileDetails = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-profile");
      console.log("kk", data?.profile);
      setRaffleId(data?.profile?.raffleId);
      setOriginalRaffleId(data?.profile?.raffleId);
      setFirstName(data?.profile?.firstName);
      setLastName(data?.profile?.lastName);
      setPhone(data?.profile?.phone);
      setWhatsapp(data?.profile?.whatsapp);
      const dateString = data?.profile?.dob;

      const date = new Date(dateString);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      setDob(formattedDate);
      const nat = {
        value: data?.profile?.nationality,
        label: data?.profile?.nationality,
      };
      setNationality(nat);
      const res = {
        value: data?.profile?.residence,
        label: data?.profile?.residence,
      };
      setResidence(res);
    } catch (error) {
      console.log("error at profile page", error);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getProfileDetails();
      //getParticipationDetails();
    }
  }, [auth?.token]);

  // ///////////////////////////////////////////////////////////////////

  // Submit form AUTO
  const autoSubmit = async (email, password, type) => {
    console.log("eeeeee3", email, password, type);
    try {
      const res = await axios.post("/api/v1/auth/login", {
        email,
        password,
      });
      console.log("1111");
      if (res && res.data.success) {
        console.log("2222");
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(location.state || "/profile");
      } else {
        console.log("3333");
        switch (res.data.message) {
          case "Email is not Registered":
            Swal.fire({
              text: "This Platform requires more accurate information about yourself",
              confirmButtonColor: "#DD8A37",
              customClass: {
                confirmButton: "btt",
              },
            });
            setEmail(email);
            setPassword(password);
            setValidationType(type);
            setSignupOptionsVisible(false);
            setEmailPassVisible(false);
            setCustomFieldVisible(true);
            break;
          case "Invalid Email or Password":
            toast.error("Already Registered, use other Login option");
            break;
          default:
            toast.error("Something went wrong");
            break;
        }
      }
    } catch (error) {
      console.log("4444");
      console.log("5555", error);
      toast.error("Signup Error!");
    }
  };

  // facebook login start
  const lol = () => {
    console.log("Pressed");
    window.FB.login(
      function (response) {
        if (response.status === "connected") {
          // Logged into your webpage and Facebook.
          // console.log(response)
          window.FB.api(
            "/me",
            "GET",
            { fields: "id,name,birthday,picture{url},gender,email" },
            function (response) {
              console.log(response);
              autoSubmit(response.email, response.id);
            }
          );
        } else {
          // The person is not logged into your webpage or we are unable to tell.
        }
      },
      { scope: "email" }
    );
  };

  // facebook login end

  // google login start
  function handleCredentialResponse(response) {
    const responsePayload = decodeJwtResponse(response.credential);

    console.log("ID: " + responsePayload.sub);
    console.log("Full Name: " + responsePayload.name);
    console.log("Given Name: " + responsePayload.given_name);
    console.log("Family Name: " + responsePayload.family_name);
    console.log("Image URL: " + responsePayload.picture);
    console.log("Email: " + responsePayload.email);

    autoSubmit(responsePayload.email, responsePayload.sub, "google");
  }

  function decodeJwtResponse(cred) {
    var jwt = cred;

    var tokens = jwt.split(".");

    console.log(JSON.parse(atob(tokens[0])));
    console.log(JSON.parse(atob(tokens[1])));

    return JSON.parse(atob(tokens[1]));
  }

  // function validateConditionA(password) {
  //   const lengthRegex = /^.{6,10}$/;
  //   const capitalLetterRegex = /^(?=.*[A-Z])/;
  //   const numberRegex = /^(?=.*\d)/;
  //   const symbolRegex = /^(?=.*[!@#%^&-])/;

  //   if (
  //     lengthRegex.test(password) &&
  //     capitalLetterRegex.test(password) &&
  //     numberRegex.test(password) &&
  //     symbolRegex.test(password)
  //   ) {
  //     setRafError("");
  //   } else {
  //     setRafError(
  //       "6-10 characters, at least 1 capital letter, 1 number, 1 symbol (!@#%^&-)"
  //     );
  //   }
  // }

  function validateConditionB(password) {
    const lengthRegex = /^.{6,30}$/;
    const capitalLetterRegex = /^(?=.*[A-Z])/;
    const numberRegex = /^(?=.*\d)/;
    const symbolRegex = /^(?=.*[!@#%^&-])/;

    if (
      lengthRegex.test(password) &&
      capitalLetterRegex.test(password) &&
      numberRegex.test(password) &&
      symbolRegex.test(password)
    ) {
      setPassError("");
    } else {
      setPassError(
        "Minimum 6 characters, at least 1 capital letter, 1 number, 1 symbol (!@#%^&-)"
      );
    }
  }

  function validateConditionC(p) {
    if (password === p) {
      setConfirmPassError("");
    } else {
      setConfirmPassError("Password Mismatch");
    }
  }

  // function validateConditionD(q) {
  //   let temp = false;
  //   raffleIds?.map((p) => {
  //     if (p.raffleId === q && q !== originalRaffleId) {
  //       temp = true;
  //     }
  //   });

  //   if (temp) {
  //     setConditionDError("Raffle Id already taken");
  //   } else {
  //     setConditionDError("");
  //   }
  // }

  function isAbove18YearsOld(dateString) {
    const currentDate = new Date();
    const selectedDate = new Date(dateString);
    const eighteenYearsAgo = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (selectedDate <= eighteenYearsAgo) {
      setDobError("");
    } else {
      setDobError("must be 18 years or above");
    }
  }

  //get all nationalities
  const getAllNationalities = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-nationalities");
      setNationalities(data.nationalities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNationalities();
  }, []);

  const options = nationalities.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  //get all nationalities
  const getAllRaffleIds = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-raffleIds");
      setRaffleIds(data.raffleIds);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRaffleIds();
  }, []);

  //const [selectedOption, setSelectedOption] = useState(null);
  // const [selectedOption2, setSelectedOption2] = useState(null);

  const handleChange = (nationality) => {
    // setSelectedOption(selectedOption);
    setNationality(nationality);
    setResidence(nationality);
    // console.log("nnnnnn",nationality)
    if (nationality?.value === "Costa Rica") {
      setResidenceError(
        "We are currently unable to register residents of Costa Rica"
      );
    } else {
      setResidenceError("");
    }
  };

  const handleChange2 = (residence) => {
    setResidence(residence);
    if (residence?.value === "Costa Rica") {
      setResidenceError(
        "We are currently unable to register residents of Costa Rica"
      );
    } else {
      setResidenceError("");
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#2f7f0e",
      minHeight: "35px",
      height: "35px",
      borderRadius: "5px",
      "&:hover": {
        borderColor: "#2f7f0e",
      },
    }),
  };
  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/v1/auth/do-reset", {
        idd: value,
        password,
        confirmPassword,
      });
      if (res && res.data.success) {
        Swal.fire({
          text: "Password Reset Successfull",
          confirmButtonColor: "#DD8A37",
          customClass: {
            confirmButton: "btt",
          },
        });
        navigate("/login");
        //
      } else {
        Swal.fire({
          text: res.data.message,
          confirmButtonColor: "#DD8A37",
          customClass: {
            confirmButton: "btt",
          },
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (seconds === 0) {
      setShowResendButton(true);
      return;
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleResendClick = async () => {
    setSeconds(60);
    setShowResendButton(false);
    // resend email
    try {
      const res = await axios.post("/api/v1/auth/resendOtp", {
        email,
      });
      console.log("Email resent");
    } catch (error) {
      console.log(error);
    }
    // resend email end
  };

  const handleResetPassword = async () => {
    try {
      const res = await axios.post("/api/v1/auth/send-reset-password-link", {
        email,
      });

      console.log("Email response", res.message);
      Swal.fire({
        text: res.message,
        confirmButtonColor: "#DD8A37",
        customClass: {
          confirmButton: "btt",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout title={"Account"}>
      <div className="row w-100 m-auto">
        <div className="col-lg-6 p-0">
          <div className="topSection">
            <div className="container-fluid p-0" style={{ lineHeight: "1.3" }}>
              <p className="headingA">PASSWORD RESET</p>

              <div className="mt-4">
                <div>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="password"
                        className="col-3 col-form-label accountLabel  m-auto"
                      >
                        Password
                      </label>
                      <div className="col-9 m-auto">
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            validateConditionB(e.target.value);
                          }}
                          className="form-control accountInput m-auto"
                          id="password"
                          required
                          style={{
                            margin: "auto",
                          }}
                        />
                      </div>
                      <div id="passwordWarning" className="form-text mt-2">
                        <p
                          id="warn2"
                          style={{
                            color: "red",
                            marginBottom: 0,
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                        >
                          {passError}
                        </p>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label
                        htmlFor="confirmPassword"
                        className="col-3 col-form-label accountLabel"
                      >
                        Confirm Password
                      </label>
                      <div className="col-9 m-auto">
                        <input
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            validateConditionC(e.target.value);
                          }}
                          className="form-control accountInput"
                          id="confirmPassword"
                          required
                        />
                      </div>
                      <div id="confirmPassWarning" className="form-text mt-2">
                        <p
                          id="warn3"
                          style={{
                            color: "red",
                            marginBottom: 0,
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                        >
                          {confirmPassError}
                        </p>
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btStandardFill p-0 mt-2"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-0"></div>
      </div>
    </Layout>
  );
};

export default ResetPasswordPage;

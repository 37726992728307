import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { useAuth } from "../context/auth";
import { useCart } from "../context/cart";
import toast from "react-hot-toast";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import VisibilitySensor from "react-visibility-sensor";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const HomePage = () => {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { value } = useParams();
  const saveReferralId = () => {
    localStorage.setItem("referralId", value);
  };

  console.log("ppp", products);

  //get all products
  const getAllProducts = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get("/api/v1/product/get-product");
      setProducts(data.products);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    if (value) {
      saveReferralId();
    }
  }, [value]);

  const referFriend = async () => {
    try {
      toast("Please refer friends");
      navigate("/referrals");
    } catch (error) {
      console.log(error);
    }
  };

  const ImageSlider = ({ imageA, imageB }) => {
    const [currentImage, setCurrentImage] = useState(imageA);
    const [isImageA, setIsImageA] = useState(true);

    useEffect(() => {
      const timer = setInterval(
        () => {
          if (isImageA) {
            setCurrentImage(imageB);
          } else {
            setCurrentImage(imageA);
          }
          setIsImageA((prevIsImageA) => !prevIsImageA);
        },
        isImageA ? 4000 : 1000
      );

      return () => clearInterval(timer);
    }, [imageA, imageB, isImageA]);

    return (
      <img
        src={currentImage}
        className="img-responsive"
        style={{ width: "100%" }}
        alt="Initiative"
      />
    );
  };

  const formatNumber = (number) => {
    return number.toLocaleString("en-US");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <Layout title={"Bear Fruit Campaigns"}>
      <div className="topSection">
        <div className="container-fluid p-0" style={{ lineHeight: "1.3" }}>
          <p className="headingA text-center">Win Big and Make a Difference!</p>

          <p className="paragraph">
            Step into a realm of thrilling raffles unlike any other. We've
            reimagined the way you participate in chance-based contests by
            infusing them with the power of giving. With our platform, your
            dreams of winning substantial rewards merge seamlessly with your
            passion for supporting worthy causes.
          </p>
          <p className="paragraph">
            At Bear Fruit Campaigns, we understand that true success is measured
            not just by personal gain but also by the positive change we create.
            That's why we've curated a collection of captivating campaigns, each
            representing a unique cause close to our hearts. From poverty
            alleviation and healthcare to education and disaster reliefs, every
            campaign you choose fuels our collective efforts to make the world a
            better place.
          </p>
          <p className="paragraph">
            But that's not all. We believe in inclusivity and making a positive
            change accessible to everyone, so we also offer a campaign that's
            absolutely free to participate in. By simply referring others to our
            platform, you not only stand a chance to win rewards but also
            contribute to our philanthropic endeavours. We donate on your
            behalf, ensuring that the impact of your involvement reaches even
            further.
          </p>
          <p className="paragraph">
            Contribute and make a difference, it will bear fruit for you too…
          </p>
          <p className="paragraph" style={{ marginBottom: 0, fontSize: 12 }}>
            For more information, visit{" "}
            <Link to="/how-we-operate" className="aTags paragraph">
              How We Operate
            </Link>{" "}
            and{" "}
            <Link to="/faq" className="aTags paragraph">
              FAQ
            </Link>
            .
          </p>
        </div>
      </div>

      {/* card section */}
      <div className="topSection">
        <div className="container-fluid p-0">
          <div className="row">
            {/* 1 */}
            {isLoading && (
              <>
                <p></p>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <Skeleton count={3} height="250px" width="100%" />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <Skeleton count={3} height="250px" width="100%" />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <Skeleton count={3} height="250px" width="100%" />
                </div>
              </>
            )}
            {products?.map((p) => (
              <div
                className={`col-lg-4 col-md-6 col-sm-6 imageDiv ${
                  p.status === "Completed" ? "completed" : ""
                }`}
              >
                <br />

                {/* PROGRESS BAR */}
                <div
                  className="tagPercentage comp"
                  style={{ width: 100, height: 100 }}
                >
                  <VisibilitySensor>
                    {({ isVisible }) => {
                      function format2Decimals(str) {
                        const num = parseFloat(str);
                        return Math.round(num * 100) / 100;
                      }
                      const per = (p.soldEntries / p.totalEntries) * 100;
                      const perc = format2Decimals(per);

                      const percentage = isVisible ? perc : 0;
                      return (
                        <CircularProgressbar
                          value={percentage}
                          text={`${percentage}%`}
                          background={"true"}
                          styles={buildStyles({
                            pathColor: "#2F7F0E",
                            textColor: "#2F7F0E",
                            trailColor: "#d6d6d6",
                            backgroundColor: "rgba(255, 255, 255, 0.486)",
                            textSize: "20px",
                            pathTransitionDuration: 2,
                          })}
                        />
                      );
                    }}
                  </VisibilitySensor>
                  {/* )} */}

                  <div></div>
                </div>

                {/* {p.status === "Completed" ? (
                  ""
                ) : (
                  <> */}
                <div className="extraText">
                  <p style={{ fontSize: "12px", color: "#666666" }}>Campaign</p>
                </div>

                <div className="extraText2">
                  <p style={{ fontSize: "12px", color: "#666666" }}>Complete</p>
                </div>
                {/* </>
                )} */}

                <div className="tagButton comp">
                  <a
                    className="btn btStandardFill"
                    // onClick={
                    //   p.status === "Completed"
                    //     ? () => {
                    //         toast.error("Campaign Closed");
                    //       }
                    //     : p.contributeAmount === 0
                    //     ? () => {
                    //         {
                    //           referFriend();
                    //         }
                    //       }
                    //     : () => {
                    //         var newP = p;

                    //         const handleDoubling = (id) => {
                    //           setCart((cart) => {
                    //             const updatedCart = cart.map((newP) =>
                    //               id === newP._id
                    //                 ? { ...newP, quantity: newP.quantity + 1 }
                    //                 : newP
                    //             );

                    //             localStorage.setItem(
                    //               "cart",
                    //               JSON.stringify(updatedCart)
                    //             );
                    //             return updatedCart;
                    //           });
                    //         };

                    //         handleDoubling(p._id);

                    //         var abc = false;
                    //         cart.map((newP) => {
                    //           console.log(
                    //             "--------------------------",
                    //             newP.id,
                    //             p._id
                    //           );
                    //           if (newP._id === p._id) {
                    //             abc = true;
                    //           }
                    //         });

                    //         if (abc === false) {
                    //           newP.quantity = 1;
                    //           setCart([...cart, newP]);
                    //           localStorage.setItem(
                    //             "cart",
                    //             JSON.stringify([...cart, newP])
                    //           );
                    //         }
                    //         console.log("pppppp", newP); //
                    //         console.log("caaaaaart", cart);
                    //         toast.success("Added to Cart");
                    //       }
                    // }
                    onClick={
                      p.status === "Completed"
                        ? () => {
                            toast.error("Campaign Closed");
                          }
                        : () => {
                          toast.error("Coming Soon");
                          }
                    }
                    style={{ color: "white" }}
                  >
                    Contribute ${p.contributeAmount}
                  </a>
                  <br />
                  <p
                    style={{
                      color: "transparent",
                      marginBottom: 0,
                      fontSize: 12,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <b>≈ IRR 0</b>
                  </p>
                </div>

                <div className="tagOutOf comp">
                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: 12,
                      float: "left",
                    }}
                  >
                    <b>
                      {p.completedDate
                        ? `Completed on ${formatDate(p.completedDate)}`
                        : ""}
                    </b>
                  </p>
                  <br />
                  <p
                    style={{
                      color: "#666666",
                      marginBottom: -2,
                      float: "left",
                      fontSize: 12,
                    }}
                  >
                    <span style={{ color: "#2F7F0E" }}>
                      {formatNumber(p.totalEntries - p.soldEntries)}
                    </span>{" "}
                    out of {formatNumber(p.totalEntries)} entries remain for
                    raffle draw
                  </p>
                </div>
                <div className="tagDollar comp">
                  <a
                    className="ora"
                    style={{
                      color: "white",
                      fontSize: 10,
                      backgroundColor: "#DD8A37",
                      padding: "2px 2px",
                      margin: 0,
                      float: "left",
                    }}
                  >
                    Raffle Reward
                  </a>
                  <br />
                  <a
                    className="ora"
                    style={{
                      color: "white",
                      fontSize: 24,
                      backgroundColor: "#DD8A37",
                      padding: "0 1px",
                      margin: "0 1px 0 0",
                      float: "left",
                    }}
                  >
                    $
                  </a>
                  {p.rewardAmount
                    .toString()
                    .split("")
                    .map((digit, index) => (
                      <a key={index} className="ora">
                        {digit}
                      </a>
                    ))}
                  {/* <a className="ora">5</a>
                <a className="ora">0</a>
                <a className="ora">0</a> */}
                </div>
                <div className="card cardMain">
                  <div className="container cont">
                    <ImageSlider
                      imageA={`/api/v1/product/product-photo/${p._id}`}
                      imageB={
                        p.status === "Completed"
                          ? "../../images/bf-thankyou.jpg"
                          : "../../images/bf-win.jpg"
                      }
                    />
                    <img
                      src="../images/transparent-layer.png"
                      className="img-responsive overlayImg"
                      style={{ width: "100%" }}
                      alt="Initiative"
                    />
                  </div>
                  <div className="tagInitiative">
                    <p
                      style={{
                        color: "#DD8A37",
                        marginBottom: 0,
                        fontSize: 14,
                        marginTop: 0,
                        paddingTop: 0,
                      }}
                      className="comp"
                    >
                      <b>{p.initiative}</b>
                    </p>
                    <p
                      style={{
                        color: "#2F7F0E",
                        float: "left",
                        fontSize: 12,
                        fontWeight: 700,
                      }}
                    >
                      <span className="comp">
                        DONATION ${formatNumber(p.targetAmount)}
                      </span>
                      <span>
                        <Link
                          to="/campaign-results"
                          style={{ textDecoration: "none", color: "#DD8A37" }}
                        >
                          {p.status === "Completed" ? "  Campaign Results" : ""}
                        </Link>
                      </span>
                    </p>
                    <p
                      style={{
                        color: "#666666",
                        float: "right",
                        fontSize: 12,
                      }}
                    >
                      BF-{p.code}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;

import React from "react";
import Layout from "../components/Layout/Layout";

const About = () => { 
  return (
    <Layout title={"About"}>
      <h1>About</h1>
    </Layout>
    
  );
};

export default About;

import Layout from "../components/Layout/Layout";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
const Spinner = () => {
  const [count, setCount] = useState(1);
const navigate = useNavigate();
const location = useLocation();

useEffect(() => {
  const interval = setInterval(() => {
    setCount((prevValue) => prevValue - 1);
  }, 500);

  if (count === 0) {
    clearInterval(interval);
    navigate("/no-access", {
      state: location.pathname,
    });
  }

  return () => clearInterval(interval);
}, [count, navigate, location]);
  return (
    <>
      <Layout>
        {/* <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div> */}
      </Layout>
    </>
  );
};

export default Spinner;

import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import Swal from "sweetalert2";

const UnsubscribePage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { value } = useParams();
  const holdEmail = () => {
    setEmail(value);
  };

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(email);
      const res = await axios.post("/api/v1/auth/unsubscribe-email", {
        email,
      });
      if (res && res.data.success) {
        Swal.fire({
          text: "Unsubscribed",
          confirmButtonColor: "#DD8A37",
          customClass: {
            confirmButton: "btt",
          },
        });
        setEmail("");
        navigate("/beta");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (value) {
      holdEmail();
    }
  }, [value]);

  return (
    <Layout title={"Unsubscribe"}>
      <div
        className="profile-container"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        {/* <p className="headingA">Unsubscribe</p> */}
        <div className="row">
          <div className="column" style={{ textAlign: "center" }}>
            <form onSubmit={handleSubmit}>
              <div className="mb-3 row">
                <label
                  htmlFor="email"
                  className="col-3 col-form-label accountLabel m-auto"
                  style={{ textAlign: "left" }}
                >
                  Email
                </label>
                <div className="col-9 m-auto">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control accountInput p-auto"
                    id="email"
                    name="email"
                    placeholder="Your Email"
                    autoComplete="off"
                    required
                    style={{
                      marginBottom: 0,
                      borderColor: " #2f7f0e",
                    }}
                  />
                </div>
              </div>
              <br />
              <input
                type="submit"
                id="submit"
                style={{ width: "7.3rem" }}
                value="Unsubscribe"
              />
            </form>
            <div style={{ textAlign: "center" }}>
              <p id="status" style={{ display: "none" }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UnsubscribePage;

import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Select from "react-select";
import { useAuth } from "../context/auth";
import Swal from "sweetalert2";

const EditAccount = () => {
  const [raffleId, setRaffleId] = useState("");
  const [originalRaffleId, setOriginalRaffleId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [dob, setDob] = useState("");
  const [nationality, setNationality] = useState("");
  const [residence, setResidence] = useState("");
  const [referralId, setReferralId] = useState("");
  const [rafError, setRafError] = useState("");
  const [passError, setPassError] = useState("");
  const [confirmPassError, setConfirmPassError] = useState("");
  const [dobError, setDobError] = useState("");
  const [nationalities, setNationalities] = useState([]);
  const [raffleIds, setRaffleIds] = useState([]);
  const [conditionDError, setConditionDError] = useState([]);
  const navigate = useNavigate();
  const [customFieldVisible, setCustomFieldVisible] = useState(false);
  const [emailPassVisible, setEmailPassVisible] = useState(true);
  const [signupOptionsVisible, setSignupOptionsVisible] = useState(true);
  const [validationType, setValidationType] = useState("otp");
  const [otp, setOtp] = useState("");
  const [auth, setAuth] = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idd = queryParams.get("i");
  const passs = queryParams.get("p");
  const [otherFieldsVisible, setOtherFieldsVisible] = useState(true);
  const [otpTextFieldVisible, setOtpTextFieldVisible] = useState(false);
  const [seconds, setSeconds] = useState(120);
  const [showResendButton, setShowResendButton] = useState(false);
  const [residenceError, setResidenceError] = useState("");
  const [profile, setProfile] = useState([]);
  const [iddu, setIddu] = useState([]);

  useEffect(() => {
    if (idd && passs) {
      autoSubmit(idd, passs, "apple");
    }
  }, []);

  const existingReferralId = localStorage.getItem("referralId");

  //GET PROFILE DETAILS
  const getProfileDetails = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-profile");
      console.log("kk", data?.profile);
      setRaffleId(data?.profile?.raffleId);
      setEmail(data?.profile?.email);

      setOriginalRaffleId(data?.profile?.raffleId);
      setFirstName(data?.profile?.firstName);
      setLastName(data?.profile?.lastName);
      setPhone(data?.profile?.phone);
      setWhatsapp(data?.profile?.whatsapp);
      setIddu(data?.profile?._id);
      const dateString = data?.profile?.dob;

      const date = new Date(dateString);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      setDob(formattedDate);
      const nat = {
        value: data?.profile?.nationality,
        label: data?.profile?.nationality,
      };
      setNationality(nat);
      const res = {
        value: data?.profile?.residence,
        label: data?.profile?.residence,
      };
      setResidence(res);
    } catch (error) {
      console.log("error at profile page", error);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getProfileDetails();
      //getParticipationDetails();
    }
  }, [auth?.token]);

  // ///////////////////////////////////////////////////////////////////

  // Submit form AUTO
  const autoSubmit = async (email, password, type) => {
    console.log("eeeeee3", email, password, type);
    try {
      const res = await axios.post("/api/v1/auth/login", {
        email,
        password,
      });
      console.log("1111");
      if (res && res.data.success) {
        console.log("2222");
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(location.state || "/profile");
      } else {
        console.log("3333");
        switch (res.data.message) {
          case "Email is not Registered":
            Swal.fire({
              text: "This Platform requires more accurate information about yourself",
              confirmButtonColor: "#DD8A37",
              customClass: {
                confirmButton: "btt",
              },
            });
            setEmail(email);
            setPassword(password);
            setValidationType(type);
            setSignupOptionsVisible(false);
            setEmailPassVisible(false);
            setCustomFieldVisible(true);
            break;
          case "Invalid Email or Password":
            toast.error("Already Registered, use other Login option");
            break;
          default:
            toast.error("Something went wrong");
            break;
        }
      }
    } catch (error) {
      console.log("4444");
      console.log("5555", error);
      toast.error("Signup Error!");
    }
  };

  // facebook login start
  const lol = () => {
    console.log("Pressed");
    window.FB.login(
      function (response) {
        if (response.status === "connected") {
          // Logged into your webpage and Facebook.
          // console.log(response)
          window.FB.api(
            "/me",
            "GET",
            { fields: "id,name,birthday,picture{url},gender,email" },
            function (response) {
              console.log(response);
              autoSubmit(response.email, response.id);
            }
          );
        } else {
          // The person is not logged into your webpage or we are unable to tell.
        }
      },
      { scope: "email" }
    );
  };

  // facebook login end

  // google login start
  function handleCredentialResponse(response) {
    const responsePayload = decodeJwtResponse(response.credential);

    console.log("ID: " + responsePayload.sub);
    console.log("Full Name: " + responsePayload.name);
    console.log("Given Name: " + responsePayload.given_name);
    console.log("Family Name: " + responsePayload.family_name);
    console.log("Image URL: " + responsePayload.picture);
    console.log("Email: " + responsePayload.email);

    autoSubmit(responsePayload.email, responsePayload.sub, "google");
  }

  function decodeJwtResponse(cred) {
    var jwt = cred;

    var tokens = jwt.split(".");

    console.log(JSON.parse(atob(tokens[0])));
    console.log(JSON.parse(atob(tokens[1])));

    return JSON.parse(atob(tokens[1]));
  }

  // function validateConditionA(password) {
  //   const lengthRegex = /^.{6,10}$/;
  //   const capitalLetterRegex = /^(?=.*[A-Z])/;
  //   const numberRegex = /^(?=.*\d)/;
  //   const symbolRegex = /^(?=.*[!@#%^&-])/;

  //   if (
  //     lengthRegex.test(password) &&
  //     capitalLetterRegex.test(password) &&
  //     numberRegex.test(password) &&
  //     symbolRegex.test(password)
  //   ) {
  //     setRafError("");
  //   } else {
  //     setRafError(
  //       "6-10 characters, at least 1 capital letter, 1 number, 1 symbol (!@#%^&-)"
  //     );
  //   }
  // }

  // function validateConditionB(password) {
  //   const lengthRegex = /^.{6,10}$/;
  //   const capitalLetterRegex = /^(?=.*[A-Z])/;
  //   const numberRegex = /^(?=.*\d)/;
  //   const symbolRegex = /^(?=.*[!@#%^&-])/;

  //   if (
  //     lengthRegex.test(password) &&
  //     capitalLetterRegex.test(password) &&
  //     numberRegex.test(password) &&
  //     symbolRegex.test(password)
  //   ) {
  //     setPassError("");
  //   } else {
  //     setPassError(
  //       "6-10 characters, at least 1 capital letter, 1 number, 1 symbol (!@#%^&-)"
  //     );
  //   }
  // }

  // function validateConditionC(p) {
  //   if (password === p) {
  //     setConfirmPassError("");
  //   } else {
  //     setConfirmPassError("Password Mismatch");
  //   }
  // }

  // function validateConditionD(q) {
  //   let temp = false;
  //   raffleIds?.map((p) => {
  //     if (p.raffleId === q && q !== originalRaffleId) {
  //       temp = true;
  //     }
  //   });

  //   if (temp) {
  //     setConditionDError("Raffle Id already taken");
  //   } else {
  //     setConditionDError("");
  //   }
  // }

  function isAbove18YearsOld(dateString) {
    const currentDate = new Date();
    const selectedDate = new Date(dateString);
    const eighteenYearsAgo = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (selectedDate <= eighteenYearsAgo) {
      setDobError("");
    } else {
      setDobError("must be 18 years or above");
    }
  }

  //get all nationalities
  const getAllNationalities = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-nationalities");
      setNationalities(data.nationalities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNationalities();
  }, []);

  const options = nationalities.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  //get all nationalities
  const getAllRaffleIds = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/get-raffleIds");
      setRaffleIds(data.raffleIds);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRaffleIds();
  }, []);

  //const [selectedOption, setSelectedOption] = useState(null);
  // const [selectedOption2, setSelectedOption2] = useState(null);

  const handleChange = (nationality) => {
    // setSelectedOption(selectedOption);
    setNationality(nationality);
    setResidence(nationality);
    // console.log("nnnnnn",nationality)
    if (nationality?.value === "Costa Rica") {
      setResidenceError(
        "We are currently unable to register residents of Costa Rica"
      );
    } else {
      setResidenceError("");
    }
  };

  const handleChange2 = (residence) => {
    setResidence(residence);
    if (residence?.value === "Costa Rica") {
      setResidenceError(
        "We are currently unable to register residents of Costa Rica"
      );
    } else {
      setResidenceError("");
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: state.isFocused ? "#2F7F0E" : "#2F7F0E",
      boxShadow: state.isFocused ? "none" : provided.boxShadow,
      minHeight: "35px",
      height: "35px",
      borderRadius: "5px",
      "&:hover": {
        borderColor: "#2F7F0E",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused || state.isActive ? "#999999" : "#fff",
      color: "black",
      "&:hover": {
        background: "#999999",
      },
    }),
  };
  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/v1/auth/update-profile", {
        firstName,
        lastName,
        // email,
        // password,
        // confirmPassword,
        phone,
        whatsapp,
        dob,
        nationality,
        residence,
        // validationType,
        // referredBy: referralId,
        // otp,
      });
      if (res && res.data.success) {
        Swal.fire({
          text: "Account Updated Successfully",
          confirmButtonColor: "#DD8A37",
          customClass: {
            confirmButton: "btt",
          },
        });
        navigate(location.state || "/profile");
        //
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (seconds === 0) {
      setShowResendButton(true);
      return;
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleResendClick = async () => {
    setSeconds(60);
    setShowResendButton(false);
    // resend email
    try {
      const res = await axios.post("/api/v1/auth/resendOtp", {
        email,
      });
      console.log("Email resent");
    } catch (error) {
      console.log(error);
    }
    // resend email end
  };

  const handleResetPassword = async () => {
    try {
      const res = await axios.post("/api/v1/auth/send-reset-password-link", {
        email: email,
        userLoggedIn: true,
      });

      if (res?.data?.success) {
        navigate(`/do-password-reset/${iddu}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout title={"Account"}>
      <div className="row w-100 m-auto">
        <div className="col-lg-6 p-0">
          <div className="topSection">
            <div className="container-fluid p-0" style={{ lineHeight: "1.3" }}>
              <p
                className="paragraph mb-0"
                style={{ fontSize: 12, float: "right" }}
              >
                <p
                  className="aTags paragraph mb-0"
                  style={{ cursor: "pointer" }}
                  onClick={handleResetPassword}
                >
                  Reset Password
                </p>
              </p>
              <p className="headingA">EDIT ACCOUNT</p>

              <div className="mt-4">
                {/* {otherFieldsVisible && (
                  <div>
                    <p className="paragraph">
                      Please make sure that all the details filled below are
                      correct and can be confirmed and verified with a
                      government issued ID if required.
                    </p>
                    <p className="paragraph">
                      These details cannot be amended while you are in an active
                      campaign, and in case you win a raffle draw, a government
                      ID (copy of driver’s license, identity card, or passport)
                      and verification will be required before the transfer of
                      any rewards. Failure to verify these details may lead to
                      forfeiture of any rewards.
                    </p>
                  </div>
                )} */}

                <div>
                  <form onSubmit={handleSubmit}>
                    {otherFieldsVisible && (
                      <div>
                        {/* <div className="mb-3 row">
                          <label
                            htmlFor="raffleId"
                            className="col-3 col-form-label accountLabel"
                            style={{ margin: "auto" }}
                          >
                            Raffle ID
                          </label>
                          <div className="col-9">
                            <input
                              type="text"
                              value={raffleId}
                              onChange={(e) => {
                                setRaffleId(e.target.value);
                                setReferralId(existingReferralId);
                                validateConditionA(e.target.value);
                                validateConditionD(e.target.value);
                              }}
                          
                              className="form-control accountInput"
                              id="raffleId"
                              placeholder="6-10 characters"
                              autoComplete="off"
                              required
                              style={{
                                margin: "auto",
                                borderColor: " #2f7f0e",
                              }}
                            />
                          </div>
                          {rafError != "" && (
                            <div
                              id="raffleIdWarning"
                              className="form-text mt-2"
                            >
                              <p
                                id="warn"
                                style={{
                                  color: "red",
                                  marginBottom: 0,
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {rafError}
                              </p>
                            </div>
                          )}

                          {conditionDError != "" && (
                            <div
                              id="raffleIdTakenWarning"
                              className="form-text mt-2"
                            >
                              <p
                                id="warnD"
                                style={{
                                  color: "red",
                                  marginBottom: 0,
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {conditionDError}
                              </p>
                            </div>
                          )}
                        
                        </div> */}

                        <div className="mb-3 row">
                          <label
                            htmlFor="firstName"
                            className="col-3 col-form-label accountLabel m-auto"
                          >
                            First Name
                          </label>
                          <div className="col-9 m-auto">
                            <input
                              type="text"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="form-control accountInput p-auto"
                              id="firstName"
                              placeholder="exactly as on government ID"
                              autoComplete="off"
                              required
                              style={{
                                marginBottom: 0,
                                borderColor: " #2f7f0e",
                              }}
                            />
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <label
                            htmlFor="lastName"
                            className="col-3 col-form-label accountLabel m-auto"
                          >
                            Last Name
                          </label>
                          <div className="col-9 m-auto">
                            <input
                              type="text"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              className="form-control accountInput m-auto"
                              id="lastName"
                              placeholder="exactly as on government ID"
                              autoComplete="off"
                              style={{
                                marginBottom: 0,
                                borderColor: " #2f7f0e",
                              }}
                              required
                            />
                          </div>
                          <div id="lastNameHelp" className="form-text mt-2">
                            Your first and last name must exactly match a
                            government issued ID (copy of driver’s license,
                            identity card, or passport). In case of winning,
                            your name will be publicly announced, and you will
                            need to pass verification before the transfer of any
                            rewards. Failure to verify these details may lead to
                            forfeiture of any rewards.
                          </div>
                        </div>

                        {/* {emailPassVisible && (
                            <div>
                              <div className="mb-3 row">
                                <label
                                  htmlFor="email"
                                  className="col-3 col-form-label accountLabel"
                                >
                                  Email
                                </label>
                                <div className="col-9">
                                  <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control accountInput"
                                    id="email"
                                    placeholder="email verification required"
                                    required
                                  />
                                </div>
                              </div>

                              <div className="mb-3 row">
                                <label
                                  htmlFor="password"
                                  className="col-3 col-form-label accountLabel  m-auto"
                                >
                                  Password
                                </label>
                                <div className="col-9 m-auto">
                                  <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => {
                                      setPassword(e.target.value);
                                      validateConditionB(e.target.value);
                                    }}
                                    className="form-control accountInput m-auto"
                                    id="password"
                                    required
                                    style={{
                                      margin: "auto",
                                    }}
                                  />
                                </div>
                                <div
                                  id="passwordWarning"
                                  className="form-text mt-2"
                                >
                                  <p
                                    id="warn2"
                                    style={{
                                      color: "red",
                                      marginBottom: 0,
                                      fontSize: 12,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {passError}
                                  </p>
                                </div>
                              </div>

                              <div className="mb-3 row">
                                <label
                                  htmlFor="confirmPassword"
                                  className="col-3 col-form-label accountLabel"
                                >
                                  Confirm Password
                                </label>
                                <div className="col-9 m-auto">
                                  <input
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                      setConfirmPassword(e.target.value);
                                      validateConditionC(e.target.value);
                                    }}
                                    className="form-control accountInput"
                                    id="confirmPassword"
                                    required
                                  />
                                </div>
                                <div
                                  id="confirmPassWarning"
                                  className="form-text mt-2"
                                >
                                  <p
                                    id="warn3"
                                    style={{
                                      color: "red",
                                      marginBottom: 0,
                                      fontSize: 12,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {confirmPassError}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )} */}

                        <div className="mb-3 row">
                          <label
                            htmlFor="phone"
                            className="col-3 col-form-label accountLabel"
                          >
                            Phone
                          </label>
                          <div className="col-9">
                            <input
                              type="text"
                              value={phone}
                              onChange={(e) => {
                                setPhone(e.target.value);
                                setWhatsapp(e.target.value);
                              }}
                              className="form-control accountInput"
                              id="phone"
                              placeholder="include country code"
                              style={{ borderColor: " #2f7f0e" }}
                              required
                            />
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <label
                            htmlFor="whatsapp"
                            className="col-3 col-form-label accountLabel m-auto"
                          >
                            Whatsapp (Optional)
                          </label>
                          <div className="col-9 m-auto">
                            <input
                              type="text"
                              value={whatsapp}
                              onChange={(e) => setWhatsapp(e.target.value)}
                              className="form-control accountInput m-auto"
                              id="whatsapp"
                              placeholder="include country code"
                              style={{ borderColor: " #2f7f0e" }}
                            />
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <label
                            htmlFor="dob"
                            className="col-3 col-form-label accountLabel m-auto"
                          >
                            Date of Birth
                          </label>
                          <div className="col-9 m-auto">
                            <input
                              type="date"
                              value={dob}
                              onChange={(e) => {
                                setDob(e.target.value);
                                isAbove18YearsOld(e.target.value);
                              }}
                              className="form-control accountInput m-auto"
                              id="dob"
                              placeholder="must be 18 years or above"
                              required
                              style={{ borderColor: " #2f7f0e" }}
                            />
                          </div>
                          <div id="dobWarning" className="form-text mt-2">
                            <p
                              id="warn4"
                              style={{
                                color: "red",
                                marginBottom: 0,
                                fontSize: 12,
                                fontWeight: 600,
                              }}
                            >
                              {dobError}
                            </p>
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <label
                            htmlFor="nationality"
                            className="col-3 col-form-label accountLabel"
                          >
                            Nationality
                          </label>
                          <div className="col-9">
                            <Select
                              id="nationality"
                              options={options}
                              isClearable
                              isSearchable
                              placeholder="type to search"
                              value={nationality}
                              onChange={handleChange}
                              className="dpdown"
                              styles={customStyles}
                            />
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <label
                            htmlFor="residence"
                            className="col-3 col-form-label accountLabel"
                            style={{ paddingRight: 0 }}
                          >
                            Country of Residence
                          </label>
                          <div className="col-9 m-auto">
                            <Select
                              id="residence"
                              options={options}
                              isClearable
                              isSearchable
                              placeholder="type to search"
                              value={residence}
                              onChange={handleChange2}
                              className="dpdown"
                              styles={customStyles}
                            />
                          </div>
                          <div id="residenceWarning" className="form-text mt-2">
                            <p
                              id="warn9"
                              style={{
                                color: "red",
                                marginBottom: 0,
                                fontSize: 12,
                                fontWeight: 600,
                              }}
                            >
                              {residenceError}
                            </p>
                          </div>
                          <div id="residenceHelp" className="form-text mt-2">
                            Depending on your Nationality and Country of
                            Residence, it is your responsibility to report any
                            winnings, and pay any applicable taxes to the
                            appropriate governmental agencies.
                          </div>
                        </div>
                      </div>
                    )}

                    {otpTextFieldVisible && (
                      <div className="mb-3 row">
                        <label
                          htmlFor="otp"
                          className="col-3 col-form-label accountLabel"
                        >
                          OTP
                        </label>
                        <div className="col-9">
                          <input
                            type="text"
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value);
                            }}
                            className="form-control accountInput"
                            id="otp"
                            placeholder="Enter the OTP"
                            style={{ borderColor: " #2f7f0e" }}
                            required
                          />
                        </div>
                        <div id="otpNameHelp" className="form-text mt-2">
                          One Time Password (OTP) is sent to your email.
                        </div>
                        {showResendButton ? (
                          <button
                            onClick={handleResendClick}
                            className="btn btStandardFill p-0 m-3"
                            style={{
                              backgroundColor: "#666666",
                              borderColor: "#666666",
                            }}
                          >
                            Resend OTP
                          </button>
                        ) : (
                          <div id="otpNameHelp" className="form-text mt-2">
                            Resend in {seconds} seconds
                          </div>
                        )}
                      </div>
                    )}

                    {/* {otherFieldsVisible && (
                        <p
                          className="paragraph"
                          style={{ marginBottom: 0, fontSize: 12 }}
                        >
                          By proceeding with this registration, I confirm that I
                          have read, understood, and agree to the{" "}
                          <Link to="/rules" className="aTags paragraph">
                            General Rules
                          </Link>
                          ,{" "}
                          <Link to="/terms" className="aTags paragraph">
                            Terms & Conditions
                          </Link>
                          , and{" "}
                          <Link to="/policy" className="aTags paragraph">
                            Privacy Policy
                          </Link>
                          .
                        </p>
                      )} */}

                    <input
                      type="text"
                      value={referralId}
                      className="form-control accountInput"
                      id="referralId"
                      hidden
                      readOnly
                    />

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btStandardFill p-0 mt-2"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-0"></div>
      </div>
    </Layout>
  );
};

export default EditAccount;
